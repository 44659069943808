<template>
  <button 
    v-bind:class="{ 'flin-button': true, 'is-waiting': isWaiting }" 
    v-bind:type="type" 
    v-bind:disabled="isDisabled"
  >
    <span v-show="!isWaiting">{{ label }}</span>
    <span v-show="isWaiting" class="dual-ring"></span>
  </button>
</template>

<script>
  import store from '@/store'
  import { computed } from 'vue'

  export default {
    name: 'FlinButton',
    props: {
      isDisabled: Boolean,
      type: String,
      label: String
    },
    setup() {
      return {
        isWaiting: computed(() => store.state.isWaiting)
      }
    }
  }
</script>

<style scoped>
  .flin-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: .5rem 1rem;
    border: 0;
    border-radius: .5rem;
    background-color: rgba(0, 127, 170, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    font-family: 'Asap Medium';
    font-size: 1rem;
    text-transform: uppercase;
  }

  .flin-button:after,
  .is-waiting:after {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: .5rem;
    content: '';
  }

  .flin-button:hover::after {
    background-color: rgba(255, 255, 255, .08);
  }

  .is-waiting {
    background-color: rgba(255, 255, 255, 1);
    pointer-events: none;
  }

  .is-waiting:after {
    background-color: rgba(0, 0, 0, .16);
  }

  .is-waiting > .dual-ring:after {
    border-color: rgba(0, 0, 0, .38) transparent rgba(0, 0, 0, .38) transparent;
  }
</style>
