<template>
  <main class="main-container">
    <div class="top-bar">
      <router-link class="top-bar-link" to="/admin/register">
        <ion-icon class="top-bar-icon" name="arrow-back"></ion-icon>

        <span class="top-bar-label">Cadastro</span>
      </router-link>
    </div>

    <div class="user-summary">
      <div class="user-summary-avatar">
        <span class="user-summary-avatar-label">GS</span>
      </div>

      <h3 class="user-summary-name">{{ register.name }}</h3>

      <div class="user-summary-roles">
        <span
          v-for="(role, roleIndex) in register.roles"
          v-bind:key="roleIndex"
          v-bind:class="[
            'role-tag',
            role + '-tag'
          ]"
        >{{ translateRole(role) }}</span>
      </div>
    </div>

    <div class="user-info-container">
      <ul class="user-info-card">
        <li class="user-info-card-title">
          <span class="user-info-card-title-text">Contato</span>
        </li>

        <li class="user-info-card-item-row">
          <ion-icon class="user-info-icon is-primary" name="checkmark"></ion-icon>
          <span>{{ register.contact.email }}</span>
        </li>

        <li 
          v-for="(phone, phoneIndex) in register.contact.userPhones"
          v-bind:key="phoneIndex"
          class="user-info-card-item-row"
        >
          <ion-icon class="user-info-icon is-alert" name="alert"></ion-icon>
          <span>{{ phone.formatted_number }}</span>
        </li>
      </ul>

      <ul class="user-info-card">
        <li class="user-info-card-title">
          <span class="user-info-card-title-text">Endereço</span>
        </li>

        <li class="user-info-card-item-column">
          <span class="user-info-card-item-label">Logradouro</span>
          <span>{{ register.address.street_address }}</span>
        </li>

        <li class="user-info-card-item-column">
          <span class="user-info-card-item-label">Número</span>
          <span>{{ register.address.number }}</span>
        </li>

        <li class="user-info-card-item-column">
          <span class="user-info-card-item-label">Bairro</span>
          <span>{{ register.address.neighborhood }}</span>
        </li>

        <li class="user-info-card-item-column">
          <span class="user-info-card-item-label">Complemento</span>
          <span>{{ register.address.secondary_address }}</span>
        </li>

        <li class="user-info-card-item-column">
          <span class="user-info-card-item-label">CEP</span>
          <span>{{ register.address.zip_code }}</span>
        </li>
      </ul>

      <ul class="user-info-card">
        <li class="user-info-card-title">
          <span class="user-info-card-title-text">Documentos</span>
        </li>

        <li 
          v-for="(doc, docIndex) in register.documents"
          v-bind:key="docIndex" 
          class="user-info-card-item-column"
        >
          <span class="user-info-card-item-label is-uppercase">{{ doc.kind }}</span>
          <span>{{ doc.number }}</span>
        </li>
      </ul>

      <ul class="user-info-card">
        <li class="user-info-card-title">
          <span class="user-info-card-title-text">Observações</span>
        </li>

        <li class="user-info-card-item-row">
          <ion-icon class="user-info-icon is-primary" name="information"></ion-icon>
          <span>Cadastrou-se em {{ register.observations.created_at.date }} às {{ register.observations.created_at.time }}</span>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
  import router from '@/router'
  import store from '@/store'
  import createHttp from '@/services/http.js'

  export default {
    name: 'FlexclinAdminRegisterProfile',
    data () {
      return {
        register: {
          uuid: '',
          name: '',
          roles: [],
          contact: {
            email: '',
            userPhones: []
          },
          address: {
            street_address: '',
            number: '',
            neighborhood: '',
            secondary_address: '',
            zip_code: ''
          },
          documents: [
            {
              name: '',
              number: ''
            }
          ],
          observations: {
            created_at: {
              date: '',
              time: ''
            }
          }
        }
      }
    },
    methods: {
      translateRole(role) {
        return role == 'patient' ? 'paciente'
                 : role == 'specialist' ? 'especialista'
                 : 'admin'
      },
      async getUserData(uuid) {
        try {
          store.commit('setWaiting')

          const http = createHttp(true)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/admins/users/' + uuid)

          if (response.data)
            return response.data.data
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar a lista. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      },
      setUserData(jsonObject) {
        let user = {
          uuid: jsonObject.id,
          name: jsonObject.attributes.first_name + ' ' + jsonObject.attributes.last_name,
          roles: jsonObject.attributes.roles,
          contact: {
            email: jsonObject.attributes.email,
            userPhones: jsonObject.attributes.phone
          },
          address: jsonObject.attributes.address,
          documents: [
            jsonObject.attributes.national_identifier[0]
          ],
          observations: {
            created_at: {
              date: '',
              time: ''
            }
          }
        }

        this.register = user;
      }
    },
    beforeMount() {
      let uuid = router.currentRoute.value.params.user_id

      this.getUserData(uuid)
        .then(function(jsonObject) {
          this.setUserData(jsonObject)
      }.bind(this))
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 2.0625rem);
    padding: 6rem 1rem 0 1rem;
  }

  .top-bar {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 93.75rem;
  }

  .top-bar-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 4rem;
    padding: 0 1rem;
    text-decoration: none;
  }

  .top-bar-icon {
    margin-right: 2rem;
    color: rgba(0, 0, 0, .87);
    font-size: 1.5rem;
  }

  .top-bar-label {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.25rem;
  }

  .user-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
  }

  .user-summary-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
    margin-bottom: .5rem;
    background-image: linear-gradient(to bottom right, 
                                      rgba(0, 0, 0, .87), 
                                      rgba(176, 0, 32, 1));
  }

  .user-summary-avatar-label {
    color: rgba(255, 255, 255, 1);
    font-family: 'Asap Medium';
    font-size: 3rem;
  }

  .user-summary-name {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
  }

  .user-summary-roles {
    display: flex;
    flex-direction: row;
    margin-top: .25rem;
  }

  .role-tag {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding: .125rem .5rem;
    border-radius: .25rem;
    margin-right: .25rem;
    color: rgba(255, 255, 255, 1);
    font-family: 'Asap Medium';
    font-size: .75rem;
    transform: skew(-12deg);
  }

  .patient-tag {
    background-color: rgba(0, 0, 0, .87);
  }

  .specialist-tag {
    background-color: rgba(0, 127, 170, 1);
  }

  .admin-tag {
    background-color: rgba(176, 0, 32, 1);
  }  

  .user-info-container {
    width: 100%;
    max-width: 32rem;
    padding-top: 2rem;
  }

  .user-info-card {
    position: relative;
    border: .0625rem solid rgba(0, 0, 0, .27);
    border-radius: .5rem;
    padding: 1.75rem 1rem .75rem 1rem;
    margin-bottom: 2rem;
    list-style: none;
  }

  .user-info-card-title {
    position: absolute;
    top: -.75rem;
    left: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
  }

  .user-info-card-title-text {
    margin: 0 .5rem;
    flex-shrink: 0;
  }

  .user-info-card-item-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .user-info-card-item-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .user-info-icon {
    margin-right: .5rem;
    font-size: 1.5rem;
  }

  .user-info-icon.is-primary {
    color: rgba(0, 127, 170, 1);
  }

  .user-info-icon.is-alert {
    color: rgba(176, 0, 32, 1);
  }

  .user-info-card-item-label {
    margin-bottom: .25rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1rem;
  }

  .is-uppercase {
    text-transform: uppercase;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      padding: 5.25rem 1.5rem 0 1.5rem;
    }

    .top-bar-link {
      padding: 0 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 5.25rem 2rem 0 2rem;
      margin: 0 auto;
    }   

    .top-bar-link {
      padding: 0 2rem;
    }
  }
</style>