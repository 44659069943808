<template>
  <transition name="fade">
    <div class="modal" v-show="showModal" v-if="modalData">
      <div class="modal-header" v-if="modalData.header">
        <button class="modal-header-button" v-on:click.prevent="closeModal">
          <ion-icon class="modal-header-button-icon" v-bind:name="modalData.header.button.icon"></ion-icon>
        </button>

        <h5 class="modal-header-title">{{ modalData.header.title }}</h5>
      </div>

      <div class="modal-body" v-if="modalData.body">
        <flin-form 
          v-bind:setup="modalData.body.form" 
          v-on:emit="emitEvent($event)"
          v-on:close-modal="closeModal"
        ></flin-form>
      </div>
    </div>
  </transition>
</template>

<script>
  import FlinForm from './FlinForm.vue'

  export default {
    name: 'FlinModal',
    components: {
      'flin-form': FlinForm
    },
    emits: [
      'close',
      'set-modal'
    ],
    props: {
      showModal: {
        required: true
      },
      modalData: Object
    },
    methods: {
      emitEvent(event) {
        this.$emit('set-modal', event)
      },
      closeModal() {
        this.$emit('close', !this.showModal)
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background-color: rgba(255, 255, 255, 1);
    overflow-y: scroll;
  }

  .modal > * {
    width: 100%;
    max-width: 25rem;
  }

  .modal-header {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: .875rem 1rem;
  }

  .modal-header-button {
    position: absolute;
    top: .875rem;
    left: .625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .375rem;
    border: 0;
    border-radius: 1.125rem;
    background-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    outline: 0;
  }

  .modal-header-button:hover {
    background-color: rgba(0, 0, 0, .04);
  }

  .modal-header-button-icon {
    height: 1.5rem;
    width: 1.5rem;
    color: rgba(0, 0, 0, .87);
  }

  .modal-body {
    padding: 1rem 1rem 1.5rem 1rem;
  }

  .modal-header-title {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
    font-weight: 400;
  }

  @media only screen and (min-width: 48rem) {
    .modal {
      padding-top: 3rem;
    }
  }
</style>
