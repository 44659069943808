<template>
  <main class="main-container">
    <img 
      class="welcome-img" 
      :src="require('../../public/assets/img/welcome-image.png')"
    />

    <div class="row">
      <div class="column">
        <h2 class="heading-5 center-text">É bom ter você conosco!</h2>

        <p class="center-text">
          Agora, precisamos de algumas informações para reconhecer o seu registro profissional. Vamos lá?
        </p>
      </div>

      <flin-button
        class="btn"
        v-bind:label="'Começar'"
        v-on:click="$router.push({ name: 'specialist-register-number'})"
      ></flin-button>      
    </div>
  </main>
</template>

<script>
  import FlinButton from '../components/FlinButton.vue'

  export default {
    name: 'FlexclinSpecialistSignupWelcome',
    components: {
      'flin-button': FlinButton
    },    
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .center-text {
    text-align: center;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .row {
    display: flex; 
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 32rem;
    padding-bottom: 2rem;
  }

  .column {
    width: 100%;
    padding: 0 .5rem 2rem .5rem;
  }

  .welcome-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .btn {
    max-width: 15rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }   
  }
</style>
