<template>
  <div class="snackbar-queue">
    <flin-snackbar-queue-item
      class="snackbar-queue-item"
      v-for="(alert, index) in setup"
      v-bind:key="index"
      v-bind:message="alert.msg"
    ></flin-snackbar-queue-item>
  </div>
</template>

<script>
  import FlinSnackbarQueueItem from './FlinSnackbarQueueItem'

  export default {
    name: 'FlinSnackbarQueue',
    components: {
      'flin-snackbar-queue-item': FlinSnackbarQueueItem
    },
    props: {
      setup: Object
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .snackbar-queue {
    position: fixed;
    left: 1rem;
    bottom: 1rem;
    z-index: 16;
    width: 100%;
    max-width: calc(100vw - 2rem);
  }

  .snackbar-queue-item {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }

  .snackbar-queue-item:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 48rem) {
    .snackbar-queue {
      left: 1.5rem;
      bottom: 1.5rem;
      max-width: 21.5rem;
    }

    .snackbar-queue-item {
      display: inline-flex;
      width: unset;
    }
  }

  @media only screen and (min-width: 72rem) {
    .snackbar-queue {
      left: 2rem;
      bottom: 2rem;
    }
  }  
</style>