<template>
  <select v-bind:class="[
    'flin-input-field',
    hasError ? 'has-error' : '']"
    v-bind:disabled="isDisabled"
    v-on:focus="updateSelectError"
    v-on:blur="validateSelect"
    v-model="selected"
  >
    <option value="" disabled>---</option>

    <option
      v-for="(option, index) in options"
      v-bind:key="index"
      v-bind:value="option.id"
    >{{ option.label }}</option>
  </select>

  <label v-bind:class="[
    'flin-input-label',
    isDisabled ? 'is-disabled' : '',
    hasError ? 'has-error' : '']"
  >
    <span class="flin-label-text">{{ label }}</span>
    <span class="flin-label-hint" v-show="hint">{{ hint }}</span>
  </label>

  <button class="flin-input-button" v-show="icon">
    <ion-icon class="flin-input-button-icon" v-bind:name="icon"></ion-icon>
  </button>

  <span class="flin-input-error" v-show="hasError">{{ hasAPIError ? apiError : error }}</span>  
</template>

<script>
  export default {
    name: 'FlinSelect',
    emits: [ 'reset-errors', 'update' ],
    props: {
      type: String,
      options: Array,
      label: String,
      hint: String,
      error: String,
      regex: String,
      name: String,
      value: String,
      icon: String,
      placement: String,
      isDisabled: Boolean,
      hasError: Boolean,
      hasAPIError: Boolean,
      apiError: String
    },
    watch: {
      $props: {
        handler(obj) {
          this.selected = obj.value
        },
        deep: true
      }
    },
    data() {
      return {
        selected: ''
      }
    },
    methods: { 
      updateSelectError() {
        this.$emit('reset-errors')
      },
      validateSelect(event) {
        let regex = new RegExp(this.regex)

        let validation = {
          value: event.target.value,
          hasError: !regex.test(event.target.value)
        }

        this.$emit('update', validation)
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: rgba(255, 255, 255, 1);
  }

  select:disabled {
    background-color: revert;
  }

  .flin-input-label {
    order: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: .25rem;
  }

  .flin-label-text {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
  }

  .flin-input-label.is-disabled .flin-label-text {
    color: rgba(0, 0, 0, .38);
  }

  .flin-input-label.has-error .flin-label-text {
    color: rgba(176, 0, 32, 1);
  }

  .flin-label-hint {
    font-size: .75rem;
  }

  .flin-input-label.has-error .flin-label-hint {
    color: rgba(176, 0, 32, 1);
  }

  .flin-input-field {
    order: 2;
    padding: .5rem 2.5rem .5rem 1rem;
    border: .0625rem solid rgba(0, 0, 0, .38);
    border-radius: .5rem;
    color: rgba(0, 0, 0, .6);
    font-family: 'Open Sans';
    font-size: 1rem;
  }

  .flin-input-field.no-icons {
    padding-right: 1rem;
  }

  .flin-input-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: .5rem;
    bottom: 1.8125rem;
    height: 1.5rem;
    width: 1.5rem;
    border: 0;
    border-radius: 1.125rem;
    background-color: rgba(0, 0, 0, 0);
    pointer-events: none;
  }

  .flin-input-button-icon {
    height: 1.25rem;
    width: 1.25rem;
    color: rgba(0, 0, 0, .87);
    pointer-events: none;
  }

  .flin-input-button:hover {
    background-color: rgba(0, 0, 0, .08);
  }

  .flin-input-field[type="password"] {
    padding: .5rem 3rem .5rem 1rem;
  }

  .flin-input-field:focus {
    border: .0625rem solid rgba(0, 127, 170, 1);
    box-shadow: inset 0 0 0 .0625rem rgba(0, 127, 170, 1);
  }

  .flin-input-field.has-error {
    border: .0625rem solid rgba(176, 0, 32, 1);
    box-shadow: inset 0 0 0 .0625rem rgba(176, 0, 32, 1);
  }

  .flin-input-field:focus + .flin-input-label > .flin-label-text,
  .flin-input-field:focus + .flin-input-label > .flin-label-hint {
    color: rgba(0, 127, 170, 1);
  }

  .flin-input-error {
    order: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    margin-top: .125rem;
    color: rgba(176, 0, 32, 1);
    font-size: .75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
