<template>
  <form v-on:submit.prevent="submitForm">
    <div class="form-title-container">
      <h5 class="form-title">{{ formTitle }}</h5>
    </div>

    <div
      v-for="(input, index) in formFields"
      v-bind:key="index"
      v-bind:class="[
        'flin-form-field',
        input.placement
      ]"
    >
      <flin-select
        v-if="input.type == 'select'"
        v-bind="input"
        v-on:reset-errors="resetErrors(index)"
        v-on:update="updateForm($event, index)"
      ></flin-select>

      <flin-input
        v-else
        v-bind:setup="input"
        v-on:reset-errors="resetErrors(index)"
        v-on:update="updateForm($event, index)"
      ></flin-input>
    </div>

    <flin-button
      v-bind:is-disabled="setup.button.isDisabled"
      v-bind:type="setup.button.type"
      v-bind:label="setup.button.label"
    ></flin-button>

    <div class="form-links-group">
      <flin-form-link 
        v-for="(link, index) in setup.links"
        v-bind:key="index"
        v-bind:setup="link"
        v-on:clicked="emitEvent($event)"
      ></flin-form-link>
    </div>
  </form>
</template>

<script>
  import store from '@/store'
  import FlinInput from './FlinInput.vue'
  import FlinSelect from './FlinSelect.vue'
  import FlinButton from './FlinButton.vue'
  import FlinFormLink from './FlinFormLink.vue'

  export default {
    name: 'FlinForm',
    components: {
      'flin-input': FlinInput,
      'flin-select': FlinSelect,
      'flin-button': FlinButton,
      'flin-form-link': FlinFormLink
    },
    props: {
      setup: Object
    },
    data() {
      return {
        formTitle: this.setup.title,
        formFields: this.setup.fields,
        formAction: this.setup.api.action,
        formAPIParameter: this.setup.api.parameter || '',
        formAPIErrors: this.setup.api.errors || {},
        formData: {},
        formHasErrors: false
      }
    },
    watch: {
      setup: function () {
        this.formFields = this.setup.fields
        this.formAction = this.setup.api.action
        this.formAPIParameter = this.setup.api.parameter
        this.formAPIErrors = this.setup.api.errors
        this.formData = {}
        this.formHasErrors = false
      },
      'setup.fields': function () {
        this.formFields = this.setup.fields
      }
    },
    mounted() {
      let parameterName = this.formAPIParameter.name
      let formHasURLParameter = (this.formAPIParameter.type == 'url')

      if (formHasURLParameter) {
        this.formData[parameterName] = this.$route.params[parameterName]
      } else {
        let parameterValue = this.formAPIParameter.value

        this.formData[parameterName] = parameterValue
      }
    },
    methods: {
      emitEvent(event) {
        this.$emit('emit', event)
      },
      checkForm() {
        this.formHasErrors = false

        this.formFields.forEach(function(field) {
          let value = field.value
          let regex = new RegExp(field.regex)

          this.formData[field.name] = value
          field.hasError = !regex.test(value)

          if (field.hasError && !this.formHasErrors)
            this.formHasErrors = true
        }.bind(this))
      },
      submitForm() {
        this.checkForm()

        if (!this.formHasErrors) {
          if (this.formAction) {
            store.dispatch(this.formAction, this.formData)
              .then((response) => {
                const responseIsError = (response.status >= 400 && response.status <= 499)

                if (responseIsError) {
                  const errors = this.formAPIErrors[response.status]

                  errors.forEach(function(error) {
                    this.formFields.forEach(function(field) {
                      if (field.name == error.field) {
                        field.apiError = error.error
                        field.hasError = true
                        field.hasAPIError = true
                      }
                    }.bind(error))
                  }.bind(this))

                  this.formHasErrors = true
                }
              })
          } else {
            this.$emit('submit-form')
          }
        } else {
          store.dispatch('alert', 'Oops, o formulário possui erros.')
        }
      },
      resetErrors(index) {
        this.formFields[index].hasError = false
        this.formFields[index].hasAPIError = false
        this.formFields[index].apiError = ""
      },
      updateForm(validation, index) {
        let fieldIsState = (this.formFields[index].name == 'state')
        let fieldIsZipCode = (this.formFields[index].name == 'zipcode')
        let fieldHasError = validation.hasError

        this.formFields[index].value = validation.value
        this.formFields[index].hasError = validation.hasError

        if (fieldIsState) {
          let value = this.formFields[index].value
          let options = this.formFields[index].options

          for (let index in options) {
            let stateId = options[index].value

            if (stateId == value)
              this.$emit('update-cities', stateId)
          }
        }

        if (fieldIsZipCode) {
          if (!fieldHasError) {
            let zipCodeData = this.formFields[index].value

            store.dispatch('get_address', zipCodeData)
              .then((response) => {
                const responseIsError = (response.status >= 400 && response.status <= 599)

                if (responseIsError) {
                  const errors = this.formAPIErrors[response.status]

                  errors.forEach(function(error) {
                    this.formFields.forEach(function(field) {
                      if (field.name == error.field) {
                        field.apiError = error.error
                        field.hasError = true
                        field.hasAPIError = true
                      }
                    }.bind(error))
                  }.bind(this))

                  this.formHasErrors = true
                } else {
                  let formFields = this.formFields
                  let addressData = response.data.data.attributes

                  for (let index in formFields) {
                    for (let key in addressData) {
                      if (formFields[index]['name'] == key) {
                        if (key != 'state' && key != 'city') {
                          formFields[index]['value'] = addressData[key]
                        } else {
                          if (key == 'state')
                            this.$emit('update-cities', addressData[key]['id'])

                          formFields[index]['value'] = addressData[key]['id']
                          formFields[index]['isDisabled'] = false
                        }

                        formFields[index]['hasError'] = false
                      }
                    }
                  }
                }
              })
          }
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .flin-form-field {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 1.25rem 0;
    margin-bottom: .25rem;
  }

  .form-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 2rem 0;
  }

  .form-title {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .form-links-group {
    margin-top: 1rem;
  }

  @media only screen and (min-width: 48rem) {
    .flin-form-field.left-half,
    .flin-form-field.right-half {
      width: calc(50% - .5rem);
    }

    .flin-form-field.left-third,
    .flin-form-field.right-third {
      width: calc(33.333333% - .5rem);
    }

    .flin-form-field.left-two-thirds,
    .flin-form-field.right-two-thirds {
      width: calc(66.666666% - .5rem);
    }

    .left-half,
    .left-third,
    .left-two-thirds {
      margin-right: .5rem;
    }

    .right-half,
    .right-third,
    .right-two-thirds {
      margin-left: .5rem;
    }
  }
</style>
