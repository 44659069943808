<template>
  <main class="main-container">
    <div class="stepper">
      <div class="stepper-item">
        <div class="stepper-item-circle is-active">
          <div class="stepper-item-inner-circle"></div>  
        </div>

        <span class="stepper-item-label is-active">Registro</span>
      </div>

      <div class="stepper-item">
        <div class="stepper-item-circle"></div>
        <span class="stepper-item-label">Telefone</span>        
      </div>

      <div class="stepper-line"></div>
    </div>

    <div class="row">
      <div class="column">
        <h5 class="alt-title center-text">Etapa 1 de 2</h5>

        <h2 class="heading-4 center-text">Registro</h2>

        <p class="center-text">
          Informe o seu registro profissional. Ele será verificado para a aprovação do seu cadastro.
        </p>
      </div>
    </div>

    <div class="signup-container">
      <img 
        class="signup-img" 
        :src="require('../../public/assets/img/specialist-personal-data.png')"
      />

      <flin-form
        class="personal-data-form"
        v-bind:setup="form"
        v-on:submit-form="submitForm"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  import createHttp from '@/services/http.js'
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSpecialistSignupRegisterNumber',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: '',
          api: {
            name: 'flexclin-register',
            action: '',
            method: 'post',
            parameter: {
              type: 'constant',
              name: 'role',
              value: 'specialist',
            },
            errors: {}
          },
          fields: [
            { 
              type: "text", 
              label: "CPF", 
              error: "CPF deve ter 11 dígitos",
              regex: "^(\\d{3}).(\\d{3}).(\\d{3})-(\\d{2})$",
              name: "cpf",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "select",
              options: [],
              label: "UF do Registro", 
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "council_state",
              value: "",
              icon: "chevron-down-outline",
              placement: 'left-third',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "select",
              options: [],
              label: "Conselho do Registro", 
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "council_name",
              value: "",
              icon: "chevron-down-outline",
              placement: 'right-two-thirds',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Número de Registro",
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "number",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
          ],
          button: {
            isWaiting: false,
            isDisabled: false,
            type: "submit",
            label: "Continuar"
          },
          links: []
        }
      }
    },
    mounted() {
      this.setStates()
      this.setCouncils()

      let userDocs = store.state.user.national_identifier || []

      userDocs.forEach(function(doc) {
        let docIsCPF = (doc.kind == 'cpf')

        if (docIsCPF) {
          let docNumber = doc.number
          let formFields = this.form.fields

          formFields.forEach(function(field) {
            let fieldIsCPF = (field.name == 'cpf')

            if (fieldIsCPF)
              field.value = docNumber.replace(/\D/g,"")
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')

          }.bind(docNumber))
        }
      }.bind(this))
    },
    methods: {
      setStates() {
        this.getStates()
          .then(function(statesArray) {
            let formFields = this.form.fields
            let statesArrayIsEmpty = (statesArray == null)

            if (!statesArrayIsEmpty) {
              for (let index in formFields) {
                if (formFields[index]['name'] == 'council_state') {
                  for (let key in statesArray) {
                    let option = {
                      id: statesArray[key]['id'],
                      label: statesArray[key]['code'],
                      value: statesArray[key]['id']
                    }

                    formFields[index]['options'].push(option)
                  }
                }
              }
            }
          }.bind(this))
      },
      setCouncils() {
        this.getCouncils()
          .then(function(councilsArray) {
            let formFields = this.form.fields
            let councilsArrayIsEmpty = (councilsArray == null)

            if (!councilsArrayIsEmpty) {
              for (let index in formFields) {
                if (formFields[index]['name'] == 'council_name') {
                  for (let key in councilsArray) {
                    let option = {
                      id: councilsArray[key]['id'],
                      label: councilsArray[key]['attributes']['code'],
                      value: councilsArray[key]['id']
                    }

                    formFields[index]['options'].push(option)
                  }
                }
              }
            }
          }.bind(this))
      },
      async getStates() {
        try {
          store.commit('setWaiting')

          const http = createHttp(false)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/states')

          if (response.data)
            return response.data.states
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar os estados. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      },
      async getCouncils() {
        try {
          store.commit('setWaiting')
  
          const http = createHttp(false)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/councils')
  
          if (response.data)
            return response.data.data
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar os estados. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      },
      getFormData() {
        let formData = { council_registration: {}, national_identifier: {} }
        let formFields = this.form.fields

        formFields.forEach(function(field) {
          if (field.name == 'cpf') {
            formData.national_identifier['number'] = field.value.replace(/[.-]/g, '')
          } else {
            switch (field.name) {
              case 'council_name':
                formData.council_registration['council_id'] = field.value
                break

              case 'council_state':
                formData.council_registration['state_id'] = field.value
                break

              default:
                formData.council_registration[field.name] = field.value
                break
            }
          }
        }.bind(formData))

        return formData
      },
      async submitForm() {
        try {
          store.commit('setWaiting')

          const http = createHttp(true)
          const response = await http
            .post('https://flexclin-register.herokuapp.com/users/documents/professional_information', this.getFormData())

          if (response.data) {
            router.push({ name: 'specialist-phone-number' })
            store.dispatch('alert', 'Registro profissional cadastrado.')
          }
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro no sistema. Comunique o suporte.')
              break
          }
        } finally {
          store.commit('clearWaiting')
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .stepper {
    position: relative;
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;     
    width: 100%; 
    max-width: 25rem;  
    margin: 1.5rem 0 3rem 0;    
  }

  .stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.25rem;  
  }

  .stepper-item-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1rem;
    margin-bottom: .5rem;
    background-color: #949494;
  }

  .stepper-item-inner-circle {
    height: 1rem;
    width: 1rem;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 1);
  }

  .stepper-item-circle.is-active {
    background-color: rgba(0, 127, 170, 1);
  }

  .stepper-item-label {
    font-size: .875rem;
    font-family: 'Asap Medium';
  }

  .stepper-item-label.is-active {
    color: rgba(0, 127, 170, 1);
  }

  .stepper-line {
    position: absolute;
    top: .625rem;
    left: 3.875rem;
    height: .25rem;
    width: calc(100% - 7.75rem);
    background-color: #949494;
  }

  .alt-title {
    margin-bottom: .5rem;
  }

  .center-text {
    text-align: center;
  }

  .heading-4 {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 2rem;
  }

  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .specialist-signup-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .row {
    display: flex; 
    flex-direction: column;
    width: 100%;
    max-width: 32rem;
  }

  .column {
    width: 100%;
    padding: 0 .5rem 2rem .5rem;
  }

  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .personal-data-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signup-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .row {
      flex-direction: row; 
      justify-content: space-between; 
      padding-bottom: 2rem;
    }

    .column {
      width: 100%;
      padding: 1rem 1.25rem;
    }

    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .stepper {  
      margin: 0 0 2.125rem 0;
    }

    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .row {
      width: 75%; 
    }

    .signup-container {
      flex-direction: row;
      justify-content: center;
    }

    .personal-data-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>
