<template>
  <main class="main-container">
    <div class="reset-password-container">
      <img 
        class="reset-password-img" 
        :src="require('../../public/assets/img/reset-password-image.png')"/>

      <flin-form
        class="reset-password-form"
        v-bind:setup="form"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinResetPassword',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: 'Alterar Senha',
          api: {
            name: 'flexclin-register',
            action: 'reset_password',
            method: 'post',
            parameter: {
              type: 'url',
              name: 'password_token',
              value: ''
            }
          },
          fields: [
            { 
              type: "password", 
              label: "Senha", 
              hint: "Use letras, números e caracteres especiais", 
              error: "Senha sem letra, número e caractere especial", 
              regex: "^(?=(.*[A-Za-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{6,}$",
              name: "password",
              value: "",
              icon: "eye-outline",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            }
          ],
          button: {
            isWaiting: false,
            isDisabled: false, 
            type: "submit", 
            label: "Alterar Senha" 
          },
          links: []
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .reset-password-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .reset-password-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .reset-password-container {
      flex-direction: row;
      justify-content: center;
    }

    .reset-password-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>
