<template>
  <nav v-bind:class="{ 'navbar': true, 'has-border': (pageHasYOffset || collapseButton.isActive || avatarDropdown.isActive) }">
    <div class="navbar-logo-container">
      <router-link :to="{ name: 'index' }" class="navbar-logo-link" title="Ir para a página inicial">
        <img 
          class="navbar-logo-img" 
          :src="require('../../public/assets/img/flexclin-logo.svg')"
        />
      </router-link>
    </div>

    <button
      v-show="!isAuthenticated && hasMenu"
      v-bind:class="{ 'collapse-button': true, 'active': collapseButton.isActive }"
      v-on:click="collapseButton.isActive = !collapseButton.isActive"
    >
      <ion-icon class="collapse-button-icon" name="menu-outline"></ion-icon>
    </button>

    <flin-navbar-menu
      v-show="!isAuthenticated && hasMenu"
      v-bind:setup="setup.menu"
      v-bind:collapse-button-is-active="collapseButton.isActive"
      v-on:emit="emitEvent($event)"
    ></flin-navbar-menu>

    <flin-avatar
      v-show="isAuthenticated && hasMenu"
      v-on:click="avatarDropdown.isActive = !avatarDropdown.isActive"
      v-bind:modal-is-open="modalIsOpen"
      v-bind:dropdown-is-active="avatarDropdown.isActive"
    ></flin-avatar>

    <flin-user-menu
      v-show="isAuthenticated && hasMenu"
      v-bind:setup="setup.user_menu"
      v-bind:dropdown-is-active="avatarDropdown.isActive"
      v-on:logout="endSession"
    ></flin-user-menu>
  </nav>
</template>

<script>
  import store from '@/store'
  import FlinNavbarMenu from './FlinNavbarMenu.vue'
  import FlinAvatar from './FlinAvatar.vue'
  import FlinUserMenu from './FlinUserMenu.vue'

  export default {
    name: 'FlinNavbar',
    components: {
      'flin-navbar-menu': FlinNavbarMenu,
      'flin-avatar': FlinAvatar,
      'flin-user-menu': FlinUserMenu	
    },
    props: {
      modalIsOpen: Boolean,
      isAuthenticated: Boolean,
      setup: Object
    },
    data() {
      return {
        pageHasYOffset: false,
        collapseButton: {
          isActive: false,
          isHidden: false
        },
        avatarDropdown: {
          isActive: false
        }
      }
    },
    watch: {
      modalIsOpen: function () {
        this.collapseButton.isActive = false
        this.avatarDropdown.isActive = false
      },
      'collapseButton.isActive': function () {
        if (this.collapseButton.isActive) {
          document.body.style.overflow = 'hidden'
        } else {
          if (!this.modalIsOpen)
            document.body.style.overflow = 'unset'
        }
      },
      'collapseButton.isHidden': function () {
        if (this.collapseButton.isHidden)
          this.collapseButton.isActive = false
      },
      // eslint-disable-next-line no-unused-vars
      $route (to, from) {
        this.collapseButton.isActive = false
        this.avatarDropdown.isActive = false
      }
    },
    methods: {
      emitEvent(event) {
        this.$emit('show-modal', event)
      },
      checkCollapseButton() {
        let collapseButton = document.querySelector('.collapse-button')
        
        this.collapseButton.isHidden = (window.getComputedStyle(collapseButton, null).display == 'none')
        this.collapseButton.isActive = collapseButton.classList.contains('active')
      },
      checkPageYOffset() {
        this.pageHasYOffset = (window.pageYOffset > 0)
      },
      endSession() {
        this.avatarDropdown.isActive = false
        store.dispatch('logout')
      }
    },
    created() {
      window.addEventListener('scroll', this.checkPageYOffset)
      // Prevents mobile navbar styles and behaviors on larger screens after resize
      window.addEventListener('resize', this.checkCollapseButton)
    },
    mounted() {
      this.checkCollapseButton()
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.checkPageYOffset)
      window.removeEventListener('resize', this.checkCollapseButton)
    },
    computed: {
      hasMenu: function() {
        return (this.$route.name != 'signup' && 
                this.$route.name != 'signin' && 
                this.$route.name != 'forgot-password' &&
                this.$route.name != 'reset-password' &&
                this.$route.name != 'specialist-signup' && 
                this.$route.name != '404' && 
                this.$route.name != 'specialist-welcome' && 
                this.$route.name != 'specialist-address')
      },
      console: () => console
    }
  }
</script>

<style scoped>
  .navbar {
    position: fixed;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #FFFFFF;
  }

  .navbar:after {
    position: absolute;
    top: 4rem;
    display: block;
    height: .0625rem;
    width: 100%;
    background-color: rgba(0, 0, 0, .38);
    content: '';
    opacity: 0;
    transition: opacity .4s ease;
  }

  .navbar.has-border:after {
    opacity: 1;
    transition: opacity .4s ease;
  }

  .navbar-logo-container {
    display: flex;
    width: 100%;
    padding: .75rem 1rem;
  }

  .navbar-logo-img {
    height: 2.5rem;
    width: auto;
  }

  .navbar-logo-link {
    display: flex;
  }

  .collapse-button {
    position: absolute;
    top: .875rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.25rem;
    padding: .375rem;
    border: .0625rem solid rgba(0,0,0,.38);
    border-radius: .5rem;
    background-color: #FFFFFF;
  }

  .collapse-button:hover {
    background-color: rgba(0, 0, 0, .04);
  }

  .collapse-button.active {
    background-color: rgba(0, 0, 0, .12);
  }

  .collapse-button-icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .navbar-divider {
    border: 0;
    height: .0625rem;
    width: 100%;
    background-color: rgba(0, 0, 0, .38);
  }

  .navbar-divider.show {
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }

  @media only screen and (min-width: 48rem) {
    .navbar {
      flex-direction: column;
      align-items: flex-start;
    }

    .navbar-logo-container {
      width: unset;
      padding: .75rem 1.5rem;
    }

    .navbar-logo-img {
      height: 2.5rem;
    }

    .collapse-button {
      display: none;
    }
  }

  @media only screen and (min-width: 72rem) {
    .navbar {
      left: 50%;
      max-width: 93.75rem;
      transform: translateX(-50%);
    }

    .navbar-logo-container {
      padding: .75rem 2rem;
    }
  }
</style>
