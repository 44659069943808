<template>
  <main class="main-container">
    <div class="admin-service-title-container">
      <h2 class="admin-service-title">flexclin-register</h2>
    </div>

    <ul class="horizontal-tab-list">
      <li>
        <router-link 
          :to="{ name: 'admin-register-index' }" 
          class="horizontal-tab-link active" 
          title="Solicitações de cadastro"
        >
          <ion-icon class="horizontal-tab-link-icon" name="person-add"></ion-icon>
          <span class="horizontal-tab-link-label">Solicitações</span>
        </router-link>
      </li>

      <li>
        <router-link 
          :to="{ name: 'admin-register-registrations' }" 
          class="horizontal-tab-link" 
          title="Cadastros"
        >
          <ion-icon class="horizontal-tab-link-icon" name="people-outline"></ion-icon>
          <span class="horizontal-tab-link-label">Cadastros</span>
        </router-link>
      </li>
    </ul>

    <table class="table">
      <thead class="table-header">
        <tr>
          <th class="table-row-cell header-cell is-hidden-mobile">Nome</th>
          <th class="table-row-cell header-cell is-hidden-mobile">E-mail</th>
          <th class="table-row-cell header-cell is-hidden-mobile">Solicitada em</th>
          <th class="table-row-cell header-cell is-hidden-tablet">Solicitações</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(request, index) in requests"
          v-bind:key="index"
          v-bind:class="[
            'table-row', 
            index % 2 == 1 ? 'darken-row' : ''
          ]"
          v-bind:data-href="'/admin/register/request/' + request.id"
          title="Visualizar Solicitação"
          v-on:click="viewRequest"
        >
          <td class="table-row-cell user-name is-hidden-mobile"><span>{{ request.specialist.full_name }}</span></td>
          <td class="table-row-cell user-email is-hidden-mobile"><span>{{ request.specialist.email }}</span></td>
          <td class="table-row-cell request-date is-hidden-mobile"><span>{{ request.created_on }}</span></td>

          <td class="table-row-cell is-hidden-tablet">
            <div class="registration-container">
              <span>{{ request.specialist.full_name }}</span>
              <span class="user-email">{{ request.specialist.email }}</span>
              <span class="request-date">Solicitada em {{ request.created_on }}</span>
            </div>
          </td>

          <ion-icon class="action-icon" name="arrow-forward-circle-sharp"></ion-icon>
        </tr>
      </tbody>
      
      <tfoot></tfoot>
    </table>    
  </main>
</template>

<script>
  import router from '@/router'
  import store from '@/store'
  import createHttp from '@/services/http.js'

  export default {
    name: 'FlexclinAdminRegisterIndex',
    data () {
      return {
        requests: []
      }
    },
    methods: {
      viewRequest(event) {
        let requestPath = event.target.parentElement.dataset.href

        router.push(requestPath)
      },
      async getRequests() {
        try {
          store.commit('setWaiting')

          const http = createHttp(true)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/admins/specialist_requests')

          if (response.data)
            return response.data
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar a lista. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      }
    },
    mounted() {
      this.getRequests()
        .then(function(jsonObject) {
          this.requests = jsonObject.specialist_requests
        }.bind(this))
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 2.0625rem);
    padding: 5.25rem 1rem 2.5rem 1rem;
  }

  .admin-service-title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .horizontal-tab-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    list-style: none;
  }

  .horizontal-tab-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 7.5rem;
    border: .0625rem solid rgba(0, 127, 170, 1);
    border-radius: .5rem;
    margin-right: .5rem;
    color: rgba(0, 127, 170, 1);
    text-decoration: none;
  }

  .horizontal-tab-link.active {
    background-color: rgba(0, 127, 170, .12);
  }

  .horizontal-tab-link-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-bottom: .25rem;
  }

  .horizontal-tab-link-label {
    font-family: 'Asap Medium';
  }

  .admin-service-title {
    color: rgba(0, 0, 0, .87);
  }

  .table {
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  .table-header {
    display: table-header-group;
  }

  .table-row-cell {
    padding: .5rem;
  }

  .table-row {
    position: relative;
    cursor: pointer;
  }

  .darken-row {
    background-color: rgba(0, 0, 0, .04);
  }

  .header-cell {
    border-bottom: .125rem solid rgba(0, 0, 0, .87);
    color: rgba(0, 0, 0, .87);
    text-align: left;
  }

  .registration-container {
    display: flex;
    flex-direction: column;
  }

  .user-email,
  .request-date {
    font-size: .75rem;
  }

  .user-roles-container {
    display: flex;
    flex-direction: row;
    margin-top: .25rem;
  }

  .role-tag {
    display: flex; 
    align-items: center; 
    justify-content: center; 
    padding: .125rem .5rem;
    border-radius: .25rem;
    margin-right: .25rem;
    color: rgba(255, 255, 255, 1);
    font-family: 'Asap Medium';
    font-size: .75rem;
    transform: skew(-12deg);
  }

  .patient-tag {
    background-color: rgba(0, 0, 0, .87);
  }

  .specialist-tag {
    background-color: rgba(0, 127, 170, 1);
  }

  .admin-tag {
    background-color: rgba(176, 0, 32, 1);
  }

  .action-icon {
    position: absolute;
    top: 50%;
    right: .5rem;
    height: 1.5rem;
    width: 1.5rem; 
    color: rgba(0, 0, 0, .87);
    transform: translateY(-50%);
  }

  .is-hidden-mobile {
    display: none;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }

    .table-row:hover {
      background-color: rgba(0, 127, 170, .04);
    }

    .table-row:hover .user-name,
    .table-row:hover .user-email,
    .table-row:hover .request-date {
      color: rgba(0, 127, 170, 1);
      font-weight: bold;
    }

    .table-row:hover .action-icon {
      color: rgba(0, 127, 170, 1);
      opacity: 1;
    }

    .user-email, 
    .request-date {
      font-size: 1rem;
    }

    .user-roles-container {
      margin-top: 0;
    }    

    .action-icon {
      position: absolute;
      top: .5rem;
      right: .5rem;    
      height: 1.5rem;
      width: 1.5rem; 
      color: rgba(0, 0, 0, .87);
      opacity: 0;
      transform: unset;
    }

    .is-hidden-mobile {
      display: revert;
    }

    .is-hidden-tablet {
      display: none;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 5.25rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .table-row-cell {
      width: 33.333333%;
    }
  }
</style>