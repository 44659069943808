<template>
  <main class="main-container">
    <img 
      class="specialist-signup-img" 
      :src="require('../../public/assets/img/specialist-image.png')"
    />

    <div class="row">
      <div class="column">
        <h3 class="heading-5">O que é a Flexclin?</h3>
        <p>A Flexclin conecta pacientes com profissionais de saúde. A plataforma é 
           impulsionada por especialistas, que oferecem os seus serviços e comodidades 
           através dos recursos online disponíveis.</p>
      </div>

      <div class="column">
        <h3 class="heading-5">Por que utilizar a Flexclin?</h3>
        <p>Não importa a especialidade ou o vínculo empregatício, a Flexclin 
           fornece recursos para facilitar a rotina do profissional de saúde. Você tem 
           total controle sobre o seu perfil e os seus serviços.</p>
      </div>
    </div>

    <h2 class="heading-4">Como funciona?</h2>

    <div class="row">
      <div class="column">
        <h3 class="heading-5">Faça um cadastro</h3>
        <p>Crie o seu perfil gratuitamente. Compartilhe as suas informações profissionais 
           na plataforma.</p>
      </div>

      <div class="column">
        <h3 class="heading-5">Configure sua conta</h3>
        <p>Prepare sua conta para atender seus pacientes. Oferecemos diversos recursos 
           para te ajudar.</p>
      </div>

      <div class="column">
        <h3 class="heading-5">Conquiste pacientes</h3>
        <p>Com o seu perfil ativo, pacientes qualificados irão acessá-lo para contratação de 
           serviços.</p>
      </div>            
    </div>

    <h2 class="heading-4">Cadastre-se!</h2>

    <div class="signup-container">
      <img 
        class="signup-img" 
        :src="require('../../public/assets/img/signup-image.png')"
      />

      <flin-form
        class="signup-form"
        v-bind:setup="form"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSpecialistSignup',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: '',
          api: {
            name: 'flexclin-register',
            action: 'specialist_signup',
            method: 'post',
            parameter: {
              type: 'constant',
              name: 'role',
              value: 'specialist',
            },
            errors: {
              422: [
                { 
                  field: 'email',
                  error: 'E-mail já está cadastrado' 
                }
              ]
            }
          },
          fields: [
            { 
              type: "text", 
              label: "Nome",
              error: "Nome não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "first_name",
              value: "",
              icon: "",
              hasHalfLength: 'left',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Sobrenome", 
              error: "Sobrenome não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "last_name",
              value: "",
              icon: "",
              hasHalfLength: 'right',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "E-mail", 
              error: "E-mail deve ter o formato nome@provedor",
              regex: "^[^\\s@]+@[^\\s@]+$",
              name: "email",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "password", 
              label: "Senha", 
              hint: "Deve ter letras, números e caracteres especiais", 
              error: "Senha deve possuir pelo menos 6 caracteres", 
              regex: "^(?=(.*[A-Za-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{6,}$",
              name: "password",
              value: "",
              icon: "eye-outline",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            }
          ],
          button: {
            isWaiting: false,
            isDisabled: false,
            type: "submit",
            label: "Cadastrar"
          },
          links: [
            {
              description: "Já possui uma conta?",
              label: "Entrar",
              event: "",
              route: "signin"
            }
          ]
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .heading-4 {
    margin-bottom: 2.125rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 2rem;
  }

  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .specialist-signup-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .row {
    display: flex; 
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
  }

  .column {
    width: 100%;
    padding: 0 .5rem 2rem .5rem;
  }

  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .signup-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signup-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .row {
      flex-direction: row; 
      justify-content: space-between; 
      padding-bottom: 6rem;
    }

    .column {
      width: 100%;
      padding: 1rem 1.25rem;
    }

    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .row {
      width: 75%; 
    }

    .signup-container {
      flex-direction: row;
      justify-content: center;
    }

    .signup-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>
