<template>
  <input
    v-bind:class="[
      'flin-input-field',
      icon ? '' : 'no-icons',
      hasError ? 'has-error' : '']"
    v-bind:type="type"
    v-bind:disabled="isDisabled"
    v-on:focus="updateInputError"
    v-on:input="applyMask"
    v-on:blur="validateInput"
    v-on:keyup.enter="(e) => { e.target.blur() }"
    v-bind:value="value"
  />
    
  <label v-bind:class="[
    'flin-input-label',
    isDisabled ? 'is-disabled' : '',
    hasError ? 'has-error' : '']"
  >
    <span class="flin-label-text">{{ label }}</span>
    <span class="flin-label-hint" v-show="hint">{{ hint }}</span>
  </label>

  <button class="flin-input-button" v-show="icon" v-on:click.prevent="togglePasswordVisibility">
    <ion-icon class="flin-input-button-icon" v-bind:name="icon"></ion-icon>
  </button>
    
  <span class="flin-input-error" v-show="hasError">{{ hasAPIError ? apiError : error }}</span>
</template>

<script>
  export default {
    name: 'FlinInput',
    emits: [ 'reset-errors', 'update' ],
    props: {
      setup: Object
    },
    data() {
      return {
        type: this.setup.type,
        label: this.setup.label,
        hint: this.setup.hint,
        error: this.setup.error,
        regex: this.setup.regex,
        name: this.setup.name,
        value: this.setup.value,
        icon: this.setup.icon,
        hasHalfLength: this.setup.hasHalfLength || '',
        isDisabled: this.setup.isDisabled || false,
        hasError: this.setup.hasError,
        hasAPIError: this.setup.hasAPIError,
        apiError: this.setup.apiError
      }
    },
    watch: {
      'setup.hasError': function () {
        this.hasError = this.setup.hasError
      },
      'setup.value': function () {
        this.value = this.setup.value
      },
      'setup.hasAPIError': function () {
        this.hasAPIError = this.setup.hasAPIError
      },
      'setup.apiError': function () {
        this.apiError = this.setup.apiError
      },
      'setup.isDisabled': function () {
        this.isDisabled = this.setup.isDisabled
      }
    },
    methods: {
      togglePasswordVisibility(event) {
        let parentNode = event.target.parentNode
        let targetInput = parentNode.getElementsByTagName('input')[0]
        let inputHasPasswordType = (targetInput.type == 'password')
        
        if (inputHasPasswordType) {
          this.type = 'text'
          this.icon = 'eye-off-outline'
        } else {
          this.type = 'password'
          this.icon = 'eye-outline'
        }
      },
      updateInputError() {
        this.$emit('reset-errors')
      },
      validateInput(event) {
        let regex = new RegExp(this.regex)
        this.value = event.target.value
        this.hasError = !regex.test(this.value)
        let validation = { value: this.value, hasError: this.hasError }

        this.$emit('update', validation)
      },
      applyMask(event) {
        let inputValue = event.target.value
        let fieldIsZipCode = (this.name == 'zipcode')
        let fieldIsCPF = (this.name == 'cpf')

        switch (this.type) {
          case 'tel':
            inputValue = inputValue.replace(/\D/g,"")
              .replace(/^(\d{2})(\d)/g,"($1) $2") 
              .replace(/(\d)(\d{4})$/,"$1-$2") 

            this.value = inputValue.replace(/^(d{2})(d)/g,"($1) $2")
            break

          case 'text':
            if (fieldIsZipCode) {
              inputValue = inputValue.replace(/\D/g,"")
                .replace(/(\d{5})(\d*)$/,"$1-$2")

              this.value = inputValue.replace(/^(d{5})(d*)$/, "$1-$2")
            } else if (fieldIsCPF) {
              inputValue = inputValue.replace(/\D/g,"")
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')

              this.value = inputValue.replace(/^(d{3})(d{3})(d{3})(d{2})$/, "$1.$2.$3-$4")
            }

            break

          default: break
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .flin-input-label {
    order: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: .25rem;
  }

  .flin-label-text {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
  }

  .flin-input-label.is-disabled .flin-label-text {
    color: rgba(0, 0, 0, .38);
  }

  .flin-input-label.has-error .flin-label-text {
    color: rgba(176, 0, 32, 1);
  }

  .flin-label-hint {
    font-size: .75rem;
  }

  .flin-input-label.has-error .flin-label-hint {
    color: rgba(176, 0, 32, 1);
  }

  .flin-input-field {
    order: 2;
    padding: .5rem 3rem .5rem 1rem;
    border: .0625rem solid rgba(0, 0, 0, .38);
    border-radius: .5rem;
    color: rgba(0, 0, 0, .6);
    font-family: 'Open Sans';
    font-size: 1rem;
  }

  .flin-input-field.no-icons {
    padding-right: 1rem;
  }

  .flin-input-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: .5rem;
    bottom: 1.5625rem;
    height: 2rem;
    width: 2rem;
    border: 0;
    border-radius: 1.125rem;
    background-color: rgba(0, 0, 0, 0);
  }

  .flin-input-button-icon {
    height: 1.5rem;
    width: 1.5rem;
    color: rgba(0, 0, 0, .87);
    pointer-events: none;
  }

  .flin-input-button:hover {
    background-color: rgba(0, 0, 0, .08);
  }

  .flin-input-field[type="password"] {
    padding: .5rem 3rem .5rem 1rem;
  }

  .flin-input-field:focus {
    border: .0625rem solid rgba(0, 127, 170, 1);
    box-shadow: inset 0 0 0 .0625rem rgba(0, 127, 170, 1);
  }

  .flin-input-field.has-error {
    border: .0625rem solid rgba(176, 0, 32, 1);
    box-shadow: inset 0 0 0 .0625rem rgba(176, 0, 32, 1);
  }

  .flin-input-field:focus + .flin-input-label > .flin-label-text,
  .flin-input-field:focus + .flin-input-label > .flin-label-hint {
    color: rgba(0, 127, 170, 1);
  }

  .flin-input-error {
    order: 3;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    margin-top: .125rem;
    color: rgba(176, 0, 32, 1);
    font-size: .75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
