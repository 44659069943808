<template>
  <flin-navbar
    v-if="!$route.meta.hideNavbar"
    v-bind:setup="navbar"
    v-bind:modal-is-open="modalOpen"
    v-bind:is-authenticated="isAuthenticated"
    v-on:show-modal="setModalData($event)"
  ></flin-navbar>

  <router-view
    v-on:show-modal="setModalData($event)"
  />

  <flin-modal
    v-on:set-modal="setModalData($event)"
    v-bind:show-modal="modalOpen" 
    v-bind:modal-data="modalData" 
    v-on:close="openModal"
  ></flin-modal>

  <flin-snackbar-queue
    v-bind:setup="alerts"
  ></flin-snackbar-queue>

  <div 
    class="cookie-alert-container"
    v-show="!acceptedCookies"
  >
   <p>Este site utiliza cookies. Leia as <a href="#">Políticas de Privacidade</a>.</p>

   <button 
     class="cookie-alert-button"
     v-on:click="acceptCookies"
   >OK</button>
  </div>

  <flin-footer></flin-footer>
</template>

<script>
import store from '@/store'
import { computed } from 'vue'
import FlinNavbar from './components/FlinNavbar.vue'
import FlinFooter from './components/FlinFooter.vue'
import FlinModal from './components/FlinModal.vue'
import FlinSnackbarQueue from './components/FlinSnackbarQueue'

export default {
  name: 'FlexclinApp',
  components: {
    'flin-navbar': FlinNavbar,
    'flin-footer': FlinFooter,
    'flin-modal': FlinModal,
    'flin-snackbar-queue': FlinSnackbarQueue
  },
  setup() {
    return {
      isAuthenticated: computed(() => store.getters.isAuthenticated),
      acceptedCookies: computed(() => store.getters.acceptedCookies)
    }
  },
  data() {
    return {
      modalOpen: false,
      modalData: {},
      navbar: {
        menu: {
          links: [
            {
              icon: 'medkit-outline',
              label: 'Quero ser Especialista',
              event: '',
              route: 'specialist-signup'
            },
            {
              icon: 'person-add-outline',
              label: 'Cadastrar-se',
              event: '',
              route: 'signup'
            },
            {
              icon: 'log-in-outline',
              label: 'Entrar',
              event: '',
              route: 'signin'
            }
          ]
        },
        user_menu: {
          portals: [
            {
              image_name: 'specialist_icon.png',
              description: {
                title: 'Portal do Especialista',
                subtitle: 'Configure páginas e serviços'
              },
              disabled_to: ['patient', 'admin']
            },
            {
              image_name: 'admin_icon.png',
              description: {
                title: 'Portal do Admin',
                subtitle: 'Gerenciamento da Flexclin'
              },
              disabled_to: ['patient', 'specialist']
            }
          ],
          links: [
            {
              icon: 'medkit-outline',
              label: 'Quero ser Especialista',
              disabled_to: ['specialist', 'admin'],
              event: '',
              route: ''
            },
            {
              icon: 'person-outline',
              label: 'Conta',
              disabled_to: [],
              event: '',
              route: ''
            },
            {
              icon: 'help-outline',
              label: 'Ajuda',
              disabled_to: [],
              event: '',
              route: ''
            },
            {
              icon: 'log-out-outline',
              label: 'Sair',
              disabled_to: [],
              event: 'logout',
              route: ''
            }
          ]
        }
      }
    }
  },
  methods: {
    acceptCookies() {
      store.dispatch('accept_cookies')
    },
    openModal() {
      if (!this.modalOpen) {
        document.body.style.overflow="hidden"
      } else {
        let currentPath = this.$router.currentRoute.path

        document.body.style.overflow="unset"
        this.$router.replace(currentPath)
      }

      this.modalOpen = !this.modalOpen
    },
    setModalData(event) {
      this.modalData = this[event]

      if (!this.modalOpen)
        this.openModal()
    }
  },
  computed: {
    alerts: function () { return store.state.alerts },
    console: () => console
  }
}
</script>

<style scoped>
  @import url('../public/assets/css/flexclin.css');

  .cookie-alert-container { 
    position: fixed; 
    bottom: 0;
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    width: 100%;
    padding: .75rem 1rem;
    background-color: rgba(0, 0, 0, .87); 
    color: rgba(255, 255, 255, 1); 
    font-size: .875rem; 
  }

  .cookie-alert-container a {
    margin-left: .125rem;
    color: rgba(255, 255, 255, 1);
    font-weight: 'Asap Medium';
    text-decoration: underline;
  }

  .cookie-alert-button {
    padding: .5rem 1rem;
    border: none;
    border-radius: 1.125rem;
    margin-left: 1rem;
    background-color: rgba(255, 255, 255, 1);  
    color: rgba(0, 0, 0, .87);
    cursor: pointer;
  }
</style>
