<template>
  <main class="main-container">
    <img 
      class="not-found-img" 
      :src="require('../../public/assets/img/404-image.png')"
    />

    <h2 class="heading-5 center-text">Oops.. Página não encontrada!</h2>
  </main>
</template>

<script>
  export default {
    name: 'Flexclin404',
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .center-text {
    text-align: center;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .not-found-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }
  }
</style>
