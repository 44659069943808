<template>
  <div 
    v-bind:class="{ 'avatar': true, 'is-active': dropdownIsActive }"
  >
    <div class="avatar-img-letters">
      <span 
        v-if="username.firstName && username.lastName"
        class="avatar-first-letters"
      >
        {{ username.firstName.charAt(0) }}{{ username.lastName.charAt(0) }}
      </span>
    </div>

    <span class="avatar-username">{{ username.firstName }} {{ username.lastName }}</span>

    <ion-icon class="avatar-dropdown-icon" v-bind:name="dropdownIsActive ? 'caret-up' : 'caret-down'"></ion-icon>
  </div>
</template>

<script>
  import store from '@/store'
  import { computed } from 'vue'

  export default {
    name: 'FlinAvatar',
    props: {
      modalIsOpen: Boolean,
      dropdownIsActive: Boolean
    },
    data() {
      return {
        pageHasYOffset: false,
        username: {
          firstName: computed(() => store.state.user.firstName),
          lastName: computed(() => store.state.user.lastName),
          isShown: false
        }
      }
    },
    watch: {
      dropdownIsActive: function () {
        if (this.dropdownIsActive && !this.username.isShown) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'unset'
        }
      },
      'username.isShown': function() {
        if (this.username.isShown) {
          if (!this.modalIsOpen)
            document.body.style.overflow = 'unset'
        } else {
          if (this.dropdownIsActive) {
            document.body.style.overflow = 'hidden'
          }
        }
      }
    },
    methods: {
      checkAvatar() {
        let avatarUsername = document.querySelector('.avatar-username')

        if (avatarUsername) {
          this.username.isShown = !(window.getComputedStyle(avatarUsername, null).display == 'none')
        }
      },
      checkPageYOffset() {
        this.pageHasYOffset = (window.pageYOffset > 0)
      }
    },
    created() {
      window.addEventListener('resize', this.checkAvatar)
    },
    mounted() {
      this.checkAvatar()
      this.checkPageYOffset()
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .avatar {
    position: absolute;
    top: .75rem;
    right: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .25rem .625rem .25rem .25rem;
    border-radius: 1.25rem;
    cursor: pointer;
  }

  .avatar.is-active {
    background-color: rgba(0, 127, 170, .12);
  }

  .avatar:hover {
    background-color: rgba(0, 127, 170, .04);
  }

  .avatar.is-active:hover {
    background-color: rgba(0, 127, 170, .12);
  }

  .avatar-img-letters {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 1.25rem;
    margin-right: .75rem;
    background-color: rgba(0, 127, 170, 1);
  }

  .avatar-first-letters {
    color: rgba(255, 255, 255, 1);
    font-family: 'Asap Medium';
  }

  .avatar-username {
    display: none;
  }

  .avatar-dropdown-icon {
    height: 1rem;
    width: 1rem;
    color: rgba(0, 0, 0, .87);
  }

  .avatar:hover .avatar-dropdown-icon,
  .avatar.is-active .avatar-dropdown-icon {
    color: rgba(0, 127, 170, 1);
  }

  @media only screen and (min-width: 48rem) {
    .avatar-username {
      display: unset;
      margin-right: .75rem;
      color: rgba(0, 0, 0, .87);
      font-family: 'Asap Medium';
    }

    .avatar:hover .avatar-username,
    .avatar.is-active .avatar-username {
      color: rgba(0, 127, 170, 1);
    }
  }
</style>
