<template>
  <main class="main-container">
    <div class="stepper">
      <div class="stepper-item">
        <div class="stepper-item-circle is-active">
          <div class="stepper-item-inner-circle"></div>  
        </div>

        <span class="stepper-item-label is-active">Dados Pessoais</span>
      </div>

      <div class="stepper-item">
        <div class="stepper-item-circle"></div>
        <span class="stepper-item-label">Especialidades</span>        
      </div>

      <div class="stepper-line"></div>
    </div>

    <div class="row">
      <div class="column">
        <h5 class="alt-title center-text">Etapa 1 de 2</h5>

        <h2 class="heading-4 center-text">Dados Pessoais</h2>

        <p class="center-text">
          Informe um telefone e endereço pessoal. Eles serão utilizados para verificar a sua identidade.
        </p>
      </div>
    </div>

    <div class="signup-container">
      <img 
        class="signup-img" 
        :src="require('../../public/assets/img/specialist-personal-data.png')"
      />

      <flin-form
        class="personal-data-form"
        v-bind:setup="form"
        v-on:update-cities="setCities($event)"
        v-on:submit-form="submitForm"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  import createHttp from '@/services/http.js'
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSpecialistPersonalData',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: '',
          api: {
            name: 'flexclin-register',
            action: '',
            method: 'post',
            parameter: {
              type: 'constant',
              name: 'role',
              value: 'specialist',
            },
            errors: {
              500: [
                {
                  field: 'zipcode',
                  error: 'CEP não encontrado' 
                }
              ]
            }
          },
          fields: [
            { 
              type: "tel", 
              label: "Telefone",
              error: "Telefone deve ter 10 ou mais dígitos",
              regex: "\\([1-9]{2}\\) (?:[2-8]|9[1-9])[0-9]{3}\\-[0-9]{4}$",
              name: "number",
              value: "",
              icon: "",
              placement: 'left-half',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "CEP", 
              error: "CEP deve ter 8 ou mais dígitos",
              regex: "^[0-9]{5}-[0-9]{3}$",
              name: "zipcode",
              value: "",
              icon: "",
              placement: 'right-half',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Endereço", 
              error: "Endereço não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "street",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Número", 
              error: "Número não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "number",
              value: "",
              icon: "",
              placement: 'left-third',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Bairro", 
              error: "Bairro não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "neighborhood",
              value: "",
              icon: "",
              placement: 'right-two-thirds',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Complemento", 
              error: "",
              regex: "",
              name: "secondary_address",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "select",
              options: [],
              label: "Estado", 
              error: "Estado não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "state",
              value: "",
              icon: "chevron-down-outline",
              placement: 'left-third',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "select",
              options: [],
              label: "Cidade", 
              error: "Cidade não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "city",
              value: "",
              icon: "chevron-down-outline",
              placement: 'right-two-thirds',
              isDisabled: true,
              hasError: false,
              hasAPIError: false,
              apiError: ""
            }
          ],
          button: {
            isWaiting: false,
            isDisabled: false,
            type: "submit",
            label: "Continuar"
          },
          links: []
        }
      }
    },
    mounted() {
      this.setStates()
    },
    methods: {
      setStates() {
        this.getStates()
          .then(function(statesArray) {
            let formFields = this.form.fields
            let statesArrayIsEmpty = (statesArray == null)

            if (!statesArrayIsEmpty) {
              for (let index in formFields) {
                if (formFields[index]['name'] == 'state') {
                  for (let key in statesArray) {
                    let option = {
                      id: statesArray[key]['id'],
                      label: statesArray[key]['attributes']['code'],
                      value: statesArray[key]['id'],
                    }

                    formFields[index]['options'].push(option)
                  }
                }
              }
            }
          }.bind(this))
      },
      setCities(stateId) {
        this.getCities(stateId)
          .then(function(citiesArray) {
            let formFields = this.form.fields
            let citiesArrayIsEmpty = (citiesArray == null)

            if (!citiesArrayIsEmpty) {
              for (let index in formFields) {
                if (formFields[index]['name'] == 'city') {
                  formFields[index]['options'] = []

                  for (let key in citiesArray) {
                    let option = {
                      id: citiesArray[key]['id'],
                      label: citiesArray[key]['attributes']['name'],
                      value: citiesArray[key]['id']
                    }

                    formFields[index]['options'].push(option)
                  }

                  formFields[index].isDisabled = false
                }
              }
            }
          }.bind(this))
      },
      async getCities(stateId) {
        try {
          store.commit('setWaiting')

          const http = createHttp(false)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/cities', {
              params: { state_id: stateId }
            })

          if (response.data)
            return response.data.data
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar as cidades. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      },
      async getStates() {
        try {
          store.commit('setWaiting')

          const http = createHttp(false)
          const response = await http
            .get('https://flexclin-register.herokuapp.com/states')

          if (response.data)
            return response.data.data
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro ao carregar os estados. Comunique o suporte.')
              break
          }

          return null
        } finally {
          store.commit('clearWaiting')
        }
      },
      getFormData() {
        let formData = { phone: {}, address: {} }
        let formFields = this.form.fields

        formFields.forEach(function(field) {
          if (field.type == 'tel') {
            formData.phone['area_code'] = field.value.split(' ')[0].replace(/[()]/g, '')
            formData.phone['number'] = field.value.split(' ')[1].replace(/[-]/g, '')
          } else {
            switch (field.name) {
              case 'zipcode':
                formData.address['zip_code'] = field.value.replace(/[-]/g, '')
                break

              case 'street':
                formData.address['street_address'] = field.value
                break

              case 'state':
                break

              case 'city':
                formData.address['city_id'] = field.value
                break

              default:
                formData.address[field.name] = field.value
                break
            }
          }
        }.bind(formData))

        return formData
      },
      async submitForm() {
        try {
          store.commit('setWaiting')

          const http = createHttp(true)
          const response = await http
            .post('https://flexclin-register.herokuapp.com/users/documents/personal_information', this.getFormData())

          if (response.data) {
            router.push('specialist-specialities')
            store.dispatch('alert', 'Endereço e telefone cadastrados.')
          }
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro no sistema. Comunique o suporte.')
              break
          }
        } finally {
          store.commit('clearWaiting')
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .stepper {
    position: relative;
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;     
    width: 100%; 
    max-width: 25rem;  
    margin: 1.5rem 0 3rem 0;    
  }

  .stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.25rem;  
  }

  .stepper-item-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1rem;
    margin-bottom: .5rem;
    background-color: #949494;
  }

  .stepper-item-inner-circle {
    height: 1rem;
    width: 1rem;
    border-radius: .5rem;
    background-color: rgba(255, 255, 255, 1);
  }

  .stepper-item-circle.is-active {
    background-color: rgba(0, 127, 170, 1);
  }

  .stepper-item-label {
    font-size: .875rem;
    font-family: 'Asap Medium';
  }

  .stepper-item-label.is-active {
    color: rgba(0, 127, 170, 1);
  }

  .stepper-line {
    position: absolute;
    top: .625rem;
    left: 3.875rem;
    height: .25rem;
    width: calc(100% - 7.75rem);
    background-color: #949494;
  }

  .alt-title {
    margin-bottom: .5rem;
  }

  .center-text {
    text-align: center;
  }

  .heading-4 {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 2rem;
  }

  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .specialist-signup-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .row {
    display: flex; 
    flex-direction: column;
    width: 100%;
    max-width: 32rem;
  }

  .column {
    width: 100%;
    padding: 0 .5rem 2rem .5rem;
  }

  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .personal-data-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signup-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .row {
      flex-direction: row; 
      justify-content: space-between; 
      padding-bottom: 2rem;
    }

    .column {
      width: 100%;
      padding: 1rem 1.25rem;
    }

    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .stepper {  
      margin: 0 0 2.125rem 0;
    }

    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .row {
      width: 75%; 
    }

    .signup-container {
      flex-direction: row;
      justify-content: center;
    }

    .personal-data-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>
