<template>
  <li class="navbar-menu-item">
    <a 
      v-if="setup.event" 
      class="navbar-menu-item-link" 
      href="#" 
      v-on:click.prevent="emitClick()"
    >
      <ion-icon class="navbar-menu-item-icon" v-bind:name="setup.icon"></ion-icon>
      <span class="navbar-menu-item-label">{{ setup.label }}</span>
    </a>

    <router-link 
      v-else 
      v-bind:to="{ name: setup.route }" 
      class="navbar-menu-item-link"
    >
      <ion-icon class="navbar-menu-item-icon" v-bind:name="setup.icon"></ion-icon>
      <span class="navbar-menu-item-label">{{ setup.label }}</span>
    </router-link>
  </li>
</template>

<script>
  export default {
    name: 'FlinNavbarMenuLink',
    props: {
      setup: Object
    },
    methods: {
      emitClick() {
        this.$emit('clicked')
      } 
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .navbar-menu-item {
    display: flex;
    width: 100%;
  }

  .navbar-menu-item-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: .875rem 1.5rem;
    color: rgba(0, 0, 0, .87);
    text-decoration: none;
  }

  .navbar-menu-item-link:hover {
    background-color: rgba(0, 127, 170, .04);
    color: rgba(0, 127, 170, 1);
  }

  .navbar-menu-item-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1.25rem;
  }

  .navbar-menu-item-label {
    font-family: 'Asap Medium';
  }

  @media only screen and (min-width: 48rem) {
    .navbar-menu-item-link {
      padding: .5rem 1rem;
      margin: 0 .25rem;
      border-radius: .5rem;
    }

    .navbar-menu-item,
    .navbar-menu-item-link {
      width: unset;
    }

    .navbar-menu-item-icon {
      display: none;
    }
  }
</style>
