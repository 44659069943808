<template>
  <main class="main-container">
    <div class="signup-container">
      <img 
        class="signup-img" 
        :src="require('../../public/assets/img/signup-image.png')"/>

      <flin-form
        class="signup-form"
        v-bind:setup="form"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSignup',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: 'Cadastrar-se',
          api: {
            name: 'flexclin-register',
            action: 'signup',
            method: 'post',
            errors: {
              422: [
                { 
                  field: 'email',
                  error: 'E-mail já está cadastrado' 
                }
              ]
            }
          },
          fields: [
            { 
              type: "text", 
              label: "Nome",
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "first_name",
              value: "",
              icon: "",
              hasHalfLength: 'left',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "Sobrenome", 
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "last_name",
              value: "",
              icon: "",
              hasHalfLength: 'right',
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "text", 
              label: "E-mail", 
              error: "E-mail deve ter o formato nome@provedor",
              regex: "^[^\\s@]+@[^\\s@]+$",
              name: "email",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            { 
              type: "password", 
              label: "Senha", 
              hint: "Deve ter letras, números e caracteres especiais", 
              error: "Senha deve possuir pelo menos 6 caracteres", 
              regex: "^(?=(.*[A-Za-z]){1,})(?=(.*[\\d]){1,})(?=(.*[\\W]){1,})(?!.*\\s).{6,}$",
              name: "password",
              value: "",
              icon: "eye-outline",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            }
          ],
          button: {
            isWaiting: false,
            isDisabled: false,
            type: "submit",
            label: "Cadastrar"
          },
          links: [
            {
              description: "Já possui uma conta?",
              label: "Entrar",
              event: "",
              route: "signin"
            }
          ]
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .signup-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signup-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .signup-container {
      flex-direction: row;
      justify-content: center;
    }

    .signup-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>
