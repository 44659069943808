import store from '@/store'
import { createRouter, createWebHistory } from 'vue-router'

import Flexclin404 from '../views/Flexclin404.vue'
import FlexclinHome from '../views/FlexclinHome.vue'
import FlexclinSignup from '../views/FlexclinSignup.vue'
import FlexclinSignin from '../views/FlexclinSignin.vue'
import FlexclinForgotPassword from '../views/FlexclinForgotPassword.vue'
import FlexclinResetPassword from '../views/FlexclinResetPassword.vue'
import FlexclinSpecialistSignup from '../views/FlexclinSpecialistSignup.vue'
import FlexclinSpecialistSignupWelcome from '../views/FlexclinSpecialistSignupWelcome.vue'
import FlexclinSpecialistSignupPersonalData from '../views/FlexclinSpecialistSignupPersonalData.vue'
import FlexclinSpecialistSignupRegisterNumber from '../views/FlexclinSpecialistSignupRegisterNumber.vue'
import FlexclinSpecialistSignupPhoneNumber from '../views/FlexclinSpecialistSignupPhoneNumber.vue'
import FlexclinSpecialistSignupPhoneSelection from '../views/FlexclinSpecialistSignupPhoneSelection.vue'

import FlexclinAdminRegisterIndex from '../views/admin/FlexclinAdminRegisterIndex.vue'
import FlexclinAdminRegisterProfile from '../views/admin/FlexclinAdminRegisterProfile.vue'
import FlexclinAdminRegisterRegistrations from '../views/admin/FlexclinAdminRegisterRegistrations.vue'

const authGuard = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
  } else {
    next({
      name: 'signin',
      query: { redirect: to.name }
    })

    store.dispatch('alert', 'O endereço desejado exige autenticação.')
  }
};

const routes = [
  {
    path: '/',
    name: 'index',
    component: FlexclinHome
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: Flexclin404
  },
  {
    path: '/signup',
    name: 'signup',
    component: FlexclinSignup
  },
  {
    path: '/signin',
    name: 'signin',
    component: FlexclinSignin,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated) {
        next({ name: 'index' })

        store.dispatch('alert', 'A sua conta já está autenticada.')
      } else {
        next()
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: FlexclinForgotPassword
  },
  {
    path: '/reset-password/:password_token',
    name: 'reset-password',
    component: FlexclinResetPassword,
    beforeEnter: (to, from, next) => {
      const passwordToken = to.params['password_token']

      store.dispatch('validate_password_token', passwordToken)
        .then((response) => {
              const responseIsError = (response.status >= 400 && response.status <= 499)

              if (responseIsError)
                next({ name: 'index' })

              next()
            })
    }
  },
  {
    path: '/confirm-email/:confirmation_token',
    name: 'confirm-email',
    beforeEnter: (to, from, next) => {
      const postData = { confirmation_token: to.params['confirmation_token'] }

      store.dispatch('confirm_email', postData)
      next({ name: 'FlexclinHome' })
    }
  },
  {
    path: '/specialist-signup',
    name: 'specialist-signup',
    component: FlexclinSpecialistSignup
  },
  {
    path: '/specialist-signup/welcome',
    name: 'specialist-welcome',
    component: FlexclinSpecialistSignupWelcome,
    beforeEnter: authGuard
  },
  {
    path: '/specialist-signup/personal-data',
    name: 'specialist-personal-data',
    component: FlexclinSpecialistSignupPersonalData,
    beforeEnter: authGuard
  },
  {
    path: '/specialist-signup/register-number',
    name: 'specialist-register-number',
    component: FlexclinSpecialistSignupRegisterNumber,
    beforeEnter: authGuard
  },
  {
    path: '/specialist-signup/phone-number',
    name: 'specialist-phone-number',
    component: FlexclinSpecialistSignupPhoneNumber,
    beforeEnter: authGuard
  },
  {
    path: '/specialist-signup/phone-selection',
    name: 'specialist-phone-selection',
    component: FlexclinSpecialistSignupPhoneSelection,
    //beforeEnter: authGuard
  },
  {
    path: '/admin/register',
    name: 'admin-register-index',
    component: FlexclinAdminRegisterIndex
    //beforeEnter: authGuard
  },
  {
    path: '/admin/register/profile/:user_id',
    name: 'admin-register-profile',
    component: FlexclinAdminRegisterProfile,
    meta: {
      hideNavbar: true
    }
    //beforeEnter: authGuard
  },
  {
    path: '/admin/register/registrations',
    name: 'admin-register-registrations',
    component: FlexclinAdminRegisterRegistrations
    //beforeEnter: authGuard
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router