import { createApp } from 'vue'
import Hotjar from 'vue-hotjar'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(Hotjar, { id: '2529811' })

app.mount('#app')
