<template>
  <div class="form-link">
    <span class="form-link-description">{{ setup.description }}</span>
    
    <a
      v-if="setup.event" 
      href="#" 
      class="form-link-anchor" 
      v-on:click.prevent="emitEvent(setup.event)"
    >{{ setup.label }}</a>

    <router-link 
      v-else 
      v-bind:to="setup.route" 
      class="form-link-anchor"
    >{{ setup.label }}</router-link>
  </div>
</template>

<script>
  export default {
    name: 'FlinFormLink.vue',
    props: {
      setup: Object
    },
    methods: {
      emitEvent(event) {
        this.$emit('clicked', event)
      }
    }
  }
</script>

<style scoped>
  .form-link {
    margin-top: .5rem;
  }

  .form-link-description {
    margin-right: .75rem;
    color: rgba(0, 0, 0, .87);
  }

  .form-link-anchor {
    color: rgba(0, 127, 170, 1);
    font-family: 'Asap Medium';
    text-decoration: none;
  }

  .form-link-anchor:hover {
    text-decoration: underline;
  }
</style>
