<template>
  <li class="user-menu-item">
    <a class="user-menu-item-link" href="#" v-on:click.prevent="emitClick()">
      <ion-icon class="user-menu-item-icon" v-bind:name="setup.icon"></ion-icon>
      <span class="user-menu-item-label">{{ setup.label }}</span>
    </a>
  </li>
</template>

<script>
export default {
  name: 'FlinUserMenuLink',
  props: {
    setup: Object
  },
  methods: {
    emitClick() {
      this.$emit('clicked')
    } 
  },
  computed: {
    console: () => console
  }
}
</script>

<style scoped>
  .user-menu-item {
    display: flex;
    width: 100%;
  }

  .user-menu-item-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: .875rem 1.5rem;
    color: rgba(0, 0, 0, .87);
    text-decoration: none;
  }

  .user-menu-item-link:hover {
    background-color: rgba(0, 127, 170, .04);
    color: rgba(0, 127, 170, 1);
  }

  .user-menu-item-icon {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1.25rem;
  }

  .user-menu-item-label {
    font-family: 'Asap Medium';
  }

  @media only screen and (min-width: 48rem) {
    .user-menu-item-link {
      padding: .875rem 1rem;
    }
  }
</style>
