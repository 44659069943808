<template>
  <main class="main-container">
    <div class="signin-container">
      <img 
        class="signin-img" 
        :src="require('../../public/assets/img/signin-image.png')"/>

      <flin-form
        class="signin-form"
        v-bind:setup="form"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSignin',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: 'Entrar',
          api: {
            name: 'flexclin-register',
            action: 'signin',
            method: 'post',
            errors: {
              401: [
                {
                  field: 'email',
                  error: 'E-mail e senha incompatíveis' 
                },
                {
                  field: 'password',
                  error: 'E-mail e senha incompatíveis'
                }
              ]
            }
          },
          fields: [
            {
              type: "text", 
              label: "E-mail", 
              error: "E-mail deve ter o formato nome@provedor",
              regex: "^[^\\s@]+@[^\\s@]+$",
              name: "email",
              value: "",
              icon: "",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
            {
              type: "password",
              label: "Senha",
              error: "Senha não pode ficar vazia", 
              regex: "^(?!\\s*$).+",
              name: "password",
              value: "",
              icon: "eye-outline",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            }
          ],
          button: {
            isWaiting: false,
            isDisabled: false, 
            type: "submit", 
            label: "Entrar" 
          },
          links: [
            { 
              description: "Não possui conta?", 
              label: "Cadastrar-se",
              event: "",
              route: "signup"
            },
            { 
              description: "Esqueceu a senha?", 
              label: "Recuperar",
              event: "",
              route: "forgot-password"
            } 
          ]
        }
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .signin-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signin-img {
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .signin-container {
      flex-direction: row;
      justify-content: center;
    }

    .signin-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>