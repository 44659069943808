<template>
  <footer class="flin-footer">
    <span class="flin-footer-copyrights">© 2021 Flexclin</span>
  </footer>
</template>

<script>
  export default {
    name: 'FlinFooter'
  }
</script>

<style scoped>
  .flin-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 93.75rem;
    padding: .40625rem 1rem;
    margin: 0 auto;
  }

  .flin-footer-copyrights {
    color: rgba(0, 0, 0, .60);
    font-size: .75rem;
  }

  @media only screen and (min-width: 48rem) {
    .flin-footer {
      padding: .40625rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .flin-footer {
      justify-content: flex-start;
      padding: .40625rem 2rem;
    }
  }
</style>
