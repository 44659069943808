<template>
  <main class="main-container">
    <div class="home-intro-container">
      <form class="home-intro-form">
        <h3 class="home-intro-form-title">Agende consultas com especialistas locais</h3>

        <div class="form-input-container">
          <input class="home-intro-form-input" type="text">
          <label class="home-intro-form-label">Cidade</label>
        </div>

        <div class="form-input-container">
          <input class="home-intro-form-input" type="text">
          <label class="home-intro-form-label">Buscar por</label>
        </div>   

        <div class="form-input-container">
          <input class="home-intro-form-input" type="text">
          <label class="home-intro-form-label">Especialidade</label>
        </div>

        <button class="form-submit-btn">Buscar</button>
      </form>

      <img 
        class="home-intro-img" 
        :src="require('../../public/assets/img/home-image.png')"
      />
    </div>
  </main>
</template>

<script>
  export default {
    name: 'FlexclinHome',
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .home-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .home-intro-form {
    order: 2;
    padding: 2rem .5rem;
  }

  .home-intro-form * {
    width: 100%;
  }

  .home-intro-form-title {
    margin-bottom: 1.5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .form-input-container {
    display: flex;
    flex-direction: column;
    padding: 0 0 1.25rem 0;
    margin-bottom: .25rem;
  }

  .home-intro-form-label {
    display: flex;
    order: 1;
    margin-bottom: .25rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    line-height: 150%;
  }

  .home-intro-form-input {
    order: 2;
    width: 100%;
    padding: .5rem .5rem .5rem 1rem;
    border: .0625rem solid rgba(0, 0, 0, .38);
    border-radius: .5rem;
    color: rgba(0, 0, 0, .6);
    font-family: 'Open Sans';
    font-size: 1rem;
  }

  .home-intro-form-input:focus {
    border: .0625rem solid rgba(0, 127, 170, 1);
    box-shadow: inset 0 0 0 .0625rem rgba(0, 127, 170, 1);
  }

  .home-intro-form-input:focus + .home-intro-form-label {
    color: rgba(0, 127, 170, 1);
  }

  .form-submit-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: .5rem 1rem;
    border: 0;
    border-radius: .5rem;
    background-color: rgba(0, 127, 170, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
    font-family: 'Asap Medium';
    font-size: 1rem;
    text-transform: uppercase;
  }

  .form-submit-btn:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .08);
    content: '';
  }

  .home-intro-img {
    order: 1;
    width: 100%;
    max-width: 39rem;
  }

  @media only screen and (min-width: 48rem) {
    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .home-intro-container {
      flex-direction: row;
      justify-content: center;
    }

    .home-intro-form {
      order: 1;
      padding: 2rem;
      margin-right: 4rem;
      border: .0625rem solid rgba(0, 0, 0, .38);
      border-radius: .5rem;
    }

    .home-intro-form * {
      max-width: 18rem;
    }

    .home-intro-img {
      order: 2;
    }
  }
</style>
