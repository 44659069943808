import { createStore } from 'vuex'
import createHttp from '@/services/http.js'
import router from '@/router'

export default createStore({
  state: {
    user: {
      firstName: localStorage.getItem('userFirstName') || '',
      lastName: localStorage.getItem('userLastName') || '',
      roles: JSON.parse(localStorage.getItem('userRoles')) || [],
      national_identifier: JSON.parse(localStorage.getItem('nationalIdentifier')) || [],
      phones: JSON.parse(localStorage.getItem('userPhones')) || []
    },
    token: 'Bearer ' + localStorage.getItem('userAuthToken') || '',
    expiration: localStorage.getItem('userAuthTokenExp') || Date.now(),
    cookieConsent: Boolean(localStorage.getItem('cookieConsent')) || false,
    isWaiting: false,
    alerts: []
  },
  mutations: {
    setWaiting: (state) => state.isWaiting = true,
    clearWaiting: (state) => state.isWaiting = false,
    setAlert: (state, alert) => state.alerts.push({ msg: alert }),
    clearAlert: (state) => state.alerts.shift(),
    setCookieConsent: (state) => {
      state.cookieConsent = true

      localStorage.setItem('cookieConsent', true)
    },
    setAuthData: (state, data) => {
      let exp = new Date(0)
      
      exp.setUTCSeconds(data.exp)

      state.user['firstName'] = data.user.data.attributes.first_name
      state.user['lastName'] = data.user.data.attributes.last_name
      state.user['roles'] = data.user.data.attributes.roles
      state.user['national_identifier'] = data.user.data.attributes.national_identifier
      state.user['phones'] = data.user.data.attributes.phone
      state.token = 'Bearer ' + data.token
      state.expiration = exp

      localStorage.setItem('userFirstName', data.user.data.attributes.first_name)
      localStorage.setItem('userLastName', data.user.data.attributes.last_name)
      localStorage.setItem('userRoles', JSON.stringify(data.user.data.attributes.roles))
      localStorage.setItem('nationalIdentifier', JSON.stringify(data.user.data.attributes.national_identifier))
      localStorage.setItem('userPhones', JSON.stringify(data.user.data.attributes.phone))
      localStorage.setItem('userAuthToken', data.token)
      localStorage.setItem('userAuthTokenExp', exp)
    },
    updatePhone: (state, phones) => {
      state.user['phones'] = phones

      localStorage.setItem('userPhones', JSON.stringify(phones))
    },
    clearAuthData: (state) => {
      state.user = {}
      state.token = ''
      state.expiration = Date.now()

      localStorage.removeItem('userFirstName')
      localStorage.removeItem('userLastName')
      localStorage.removeItem('userRoles')
      localStorage.removeItem('nationalIdentifier')
      localStorage.removeItem('userPhones')
      localStorage.removeItem('userAuthToken')
      localStorage.removeItem('userAuthTokenExp')
    }
  },
  getters: {
    isAuthenticated: (state) => state.token.length > 0 && Date.parse(state.expiration) > Date.now(),
    acceptedCookies: (state) => state.cookieConsent
  },
  actions: {
    alert: ({ commit }, alert) => {
      commit('setAlert', alert)
      setTimeout(function () { commit('clearAlert') }, 4000)
    },
    accept_cookies: ({ commit }) => {
      commit('setCookieConsent', true)
    },
    update_auth_data: ({ commit }, phones) => {
      commit('updatePhone', phones)
    },
    signup: async ({ commit, dispatch }, formData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/users', formData)

        if (response.data) {
          commit('setAuthData', response.data)
          router.push('/')
          dispatch('alert', 'Cadastro realizado. Sua conta está autenticada.')
        }

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'Cadastro negado. E-mail inválido.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    signin: async ({ commit, dispatch }, formData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/auth/login', formData)

        if (response.data) {
          commit('setAuthData', response.data)

          const redirect = router.currentRoute.value.query.redirect || 'index'

          router.push({ name: redirect })
        }

        return response
      } catch(error) {
        switch (error.response.status) {
          case 401:
            dispatch('alert', 'Autenticação negada. E-mail e senha incompatíveis.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    confirm_email: async ({ commit, dispatch }, postData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/users/confirm_email', postData)

        dispatch('alert', 'A verificação de e-mail foi realizada.')

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'Operação negada. Link inválido.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    forgot_password: async ({ commit, dispatch }, formData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/users/forgot_password', formData)

        router.push('/')
        dispatch('alert', 'O link para alterar senha foi enviado ao e-mail.')

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'Operação negada. E-mail não possui conta.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    reset_password: async ({ commit, dispatch }, formData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/users/reset_password', formData)

        if (response.data) {
          commit('setAuthData', response.data)
          router.push('/')
          dispatch('alert', 'Senha alterada. Sua conta está autenticada.')
        }

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'Operação negada. Link inválido.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    specialist_signup: async ({ commit, dispatch }, formData) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.post('https://flexclin-register.herokuapp.com/users', formData)

        if (response.data) {
          commit('setAuthData', response.data)
          router.push({ name: 'specialist-welcome'})
          dispatch('alert', 'Cadastro realizado. Sua conta está autenticada.')
        }

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'E-mail já está em uso. Faça o login para continuar.')
            router.push({ name: 'specialist-welcome'})
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    get_address: async ({ commit, dispatch }, zipCode) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.get('https://flexclin-register.herokuapp.com/addresses/zipcode?zipcode=' + zipCode)

        if (response.data)
          dispatch('alert', 'Endereço preenchido com o CEP informado.')

        return response
      } catch(error) {
        switch (error.response.status) {
          case 400:
            dispatch('alert', 'CEP não encontrado.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    validate_password_token: async ({ commit, dispatch }, password_token) => {
      try {
        commit('setWaiting')

        const http = createHttp(false)
        const response = await http.get('https://flexclin-register.herokuapp.com/users/validate_password_token?password_token=' + password_token)

        return response
      } catch(error) {
        switch (error.response.status) {
          case 422:
            dispatch('alert', 'Oops.. Link inválido ou já utilizado.')
            break

          default:
            dispatch('alert', 'Erro no sistema. Tente novamente.')
            break
        }

        return error.response
      } finally {
        commit('clearWaiting')
      }
    },
    logout: ({ commit }) => {
      commit('clearAuthData')
    }
  }
})
