<template>
  <main class="main-container">
    <div class="stepper">
      <router-link 
        :to="{ name: 'specialist-register-number' }" 
        class="stepper-item" 
        title="Voltar para registro profissional"
      >
        <div class="stepper-item-circle">
          <div class="stepper-item-inner-circle"></div>  
        </div>

        <span class="stepper-item-label">Registro</span>
      </router-link>      

      <div class="stepper-item is-active">
        <div class="stepper-item-circle">
          <div class="stepper-item-inner-circle"></div>
        </div>

        <span class="stepper-item-label">Telefone</span>        
      </div>

      <div class="stepper-line"></div>
    </div>

    <div class="row">
      <div class="column">
        <h5 class="alt-title center-text">Etapa 2 de 2</h5>

        <h2 class="heading-4 center-text">Telefone</h2>

        <p class="center-text">
          Informe um telefone de contato. Ele poderá ser utilizado durante a aprovação do seu cadastro.
        </p>
      </div>
    </div>

    <div class="signup-container">
      <img 
        class="signup-img" 
        :src="require('../../public/assets/img/specialist-phone-selection.png')"
      />

      <flin-form
        class="personal-data-form"
        v-bind:setup="form"
        v-on:submit-form="submitForm"
      ></flin-form>
    </div>
  </main>
</template>

<script>
  import store from '@/store'
  import router from '@/router'
  import createHttp from '@/services/http.js'
  import FlinForm from '../components/FlinForm.vue'

  export default {
    name: 'FlexclinSpecialistSignupPhoneNumber',
    components: {
      'flin-form': FlinForm
    },
    data() {
      return {
        form: {
          title: '',
          api: {
            name: 'flexclin-register',
            action: '',
            method: 'post',
            parameter: {
              type: 'constant',
              name: 'role',
              value: 'specialist',
            },
            errors: {}
          },
          fields: [
            { 
              type: "select",
              options: [],
              label: "Telefone",
              error: "Campo não pode ficar vazio",
              regex: "^(?!\\s*$).+",
              name: "number",
              value: "",
              icon: "chevron-down-outline",
              hasError: false,
              hasAPIError: false,
              apiError: ""
            },
          ],
          button: {
            isWaiting: false,
            isDisabled: false,
            type: "submit",
            label: "Finalizar"
          },
          links: [
            { 
              description: "Trocou de número?", 
              label: "Cadastre-o",
              event: "",
              route: "phone-number"
            },
          ]
        }
      }
    },
    mounted() {
      this.setPhoneSelection();
    },
    methods: {
      setPhoneSelection() {
        let formFields = this.form.fields
        let userPhones = store.state.user.phones

        for (let index in formFields) {
          if (formFields[index]['type'] == 'select') {
            for (let key in userPhones) {
              let option = {
                id: userPhones[key]['id'],
                label: userPhones[key]['formatted_number'],
                value: userPhones[key]['id']
              }

              formFields[index]['options'].push(option)
            }
          }
        }
      },
      getFormData() {
        let formData = { phone: {} }
        let formFields = this.form.fields

        formData.phone['kind'] = 'personal'
        formData.phone['main'] = true

        for (let index in formFields) {
          if (formFields[index]['type'] == 'select') {
            formData.phone['id'] = formFields[index]['value']

            for (let key in formFields[index]['options']) {
              let optionId = formFields[index]['options'][key]['id']
              let optionNumber = formFields[index]['options'][key]['label']

              if (optionId == formFields[index]['value']) {
                formData.phone['area_code'] = optionNumber.split(' ')[0].replace(/[()]/g, '')
                formData.phone['number'] = optionNumber.split(' ')[1].replace(/[-]/g, '')
              }
            }
          }          
        }

        return formData
      },
      async submitForm() {
        try {
          store.commit('setWaiting')

          const http = createHttp(true)
          let formData = this.getFormData()

          const response = await http
            .put('https://flexclin-register.herokuapp.com/users/phones', formData)

          if (response.data) {
            router.push({ name: 'index' })

            store.dispatch('update_auth_data', response.data.data.attributes.phone)
            store.dispatch('alert', 'O seu cadastro foi enviado para aprovação.')
          }
        } catch(error) {
          switch (error.response.status) {
            default:
              store.dispatch('alert', 'Erro no sistema. Comunique o suporte.')
              break
          }
        } finally {
          store.commit('clearWaiting')
        }
      }      
    },    
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .stepper {
    position: relative;
    display: flex; 
    flex-direction: row; 
    justify-content: space-between;     
    width: 100%; 
    max-width: 25rem;  
    margin: 1.5rem 0 3rem 0;    
  }

  .stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.25rem;
    text-decoration: none;
  }

  .stepper-item-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1rem;
    margin-bottom: .5rem;
    background-color: #949494;
  }

  .stepper-item-inner-circle {
    height: 1rem;
    width: 1rem;
    border-radius: .5rem;
  }

  .stepper-item.is-active .stepper-item-inner-circle {
    background-color: rgba(255, 255, 255, 1);
  }  

  .stepper-item-circle {
    background-color: rgba(0, 127, 170, 1);
  }

  .stepper-item-label {
    color: rgba(0, 127, 170, 1);
    font-size: .875rem;
    font-family: 'Asap Medium';
  }

  .stepper-line {
    position: absolute;
    top: .625rem;
    left: 3.875rem;
    height: .25rem;
    width: calc(100% - 7.75rem);
    background-color: rgba(0, 127, 170, 1);
  }

  .alt-title {
    margin-bottom: .5rem;
  }

  .center-text {
    text-align: center;
  }

  .heading-4 {
    margin-bottom: 1rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 2rem;
  }

  .heading-5 {
    margin-bottom: .5rem;
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
    font-size: 1.5rem;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 1.9375rem);
    width: 100%;
    padding: 5.25rem 1rem 0 1rem;
  }

  .specialist-signup-img {
    width: 100%;
    max-width: 32rem;
    margin-bottom: 2rem;
  }

  .row {
    display: flex; 
    flex-direction: column;
    width: 100%;
    max-width: 32rem;
  }

  .column {
    width: 100%;
    padding: 0 .5rem 2rem .5rem;
  }

  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .personal-data-form {
    padding: 2rem .5rem;
    width: 100%;
    max-width: 25rem;
  }

  .signup-img {
    width: 100%;
    max-width: 32rem;
  }

  @media only screen and (min-width: 48rem) {
    .row {
      flex-direction: row; 
      justify-content: space-between; 
      padding-bottom: 2rem;
    }

    .column {
      width: 100%;
      padding: 1rem 1.25rem;
    }

    .main-container {
      min-height: calc(100vh - 2.0625rem);
      padding: 5.25rem 1.5rem 2.5rem 1.5rem;
    }
  }

  @media only screen and (min-width: 72rem) {
    .stepper {  
      margin: 0 0 2.125rem 0;
    }

    .main-container {
      max-width: 93.75rem;
      padding: 8rem 2rem 4rem 2rem;
      margin: 0 auto;
    }

    .row {
      width: 75%; 
    }

    .signup-container {
      flex-direction: row;
      justify-content: center;
    }

    .personal-data-form {
      padding: 0 .5rem 1.5rem .5rem;
      margin-left: 4rem;
    }        
  }
</style>