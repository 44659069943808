<template>
  <ul class="navbar-menu" v-bind:class="collapseButtonIsActive? 'show' : ''">
    <flin-navbar-menu-link
      v-for="(link, index) in setup.links"
      v-bind:key="index"
      v-bind:setup="link"
      v-on:clicked="emitEvent(link.event)"
    ></flin-navbar-menu-link>
  </ul>
</template>

<script>
  import FlinNavbarMenuLink from './FlinNavbarMenuLink.vue'

  export default {
    name: 'FlinNavbarMenu',
    components: {
      'flin-navbar-menu-link': FlinNavbarMenuLink
    },
    props: {
      setup: Object,
      collapseButtonIsActive: Boolean
    },
    methods: {
      emitEvent(event) {
        this.$emit('emit', event)
      }
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .navbar-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 0;
    width: 100%;
    list-style: none;
    opacity: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-in-out;
  }

  .navbar-menu:before,
  .navbar-menu:after {
    display: flex;
    width: 100%;
    padding: .25rem 0;
    content: '';
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }

  .navbar-menu.show {
    height: calc(100vh - 4rem);
    opacity: 1;
    overflow-y: scroll;
    transition: all 0.4s ease-in-out;
  }

  .navbar-menu.show:before,
  .navbar-menu.show:after {
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }

  @media only screen and (min-width: 48rem) {
    .navbar-menu {
      position: absolute;
      top: .75rem;
      right: 1rem;
      flex-direction: row;
      width: unset;
      padding: 0 0 0 .5rem;
      border-top: 0;
      opacity: unset;
    }

    .navbar-menu,
    .navbar-menu.show {
      height: unset;
    }

    .navbar-menu:before,
    .navbar-menu:after {
      display: none;
    }
  }

  @media only screen and (min-width: 72rem) {
    .navbar-menu {
      right: 1.5rem;
      padding: 0 0 0 1rem;
    }
  }
</style>
