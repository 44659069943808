<template>
  <div class="snackbar">
    <span>{{ message }}</span>
  </div>
</template>

<script>
  export default {
    name: 'FlinSnackbarQueueItem',
    props: {
      message: String
    },
    computed: {
      console: () => console
    }
  }
</script>

<style scoped>
  .snackbar {
    padding: .75rem 1rem;
    border-radius: .25rem;
    background-color: rgba(0, 0, 0, .87);
    color: rgba(255, 255, 255, .87);
    font-size: .875rem;
  }
</style>