<template>
  <div v-bind:class="{ 'user-menu-container': true, 'show': dropdownIsActive }">
    <ul class="user-menu">
      <router-link
        class="portal-link"
        :to="{ name: 'index' }"
        v-for="(link, index) in filteredLinks(setup.portals, true)"
        v-bind:key="index"
      >
        <div class="portal-link-img-container">
          <img
            class="portal-link-img"
            :src="require(`../../public/assets/img/${link.image_name}`)"
          >
        </div>

        <div class="portal-link-description">
          <span class="portal-link-description-title">{{ link.description.title }}</span>
          <span class="portal-link-description-subtitle">{{ link.description.subtitle }}</span>
        </div>
      </router-link>

      <hr
        class="user-menu-divider"
        v-show="userRoles.includes('specialist') || userRoles.includes('admin')"
      />

      <flin-user-menu-link
        v-for="(link, index) in filteredLinks(setup.links, false)"
        v-bind:key="index"
        v-bind:setup="link"
        v-on:clicked="emitEvent(link.event)"
      ></flin-user-menu-link>
    </ul>
  </div>
</template>

<script>
import store from '@/store'
import FlinUserMenuLink from './FlinUserMenuLink.vue'

export default {
  name: 'FlinUserMenu',
  components: {
    'flin-user-menu-link': FlinUserMenuLink
  },
  props: {
    setup: Object,
    dropdownIsActive: Boolean
  },
  methods: {
    emitEvent(event) {
      let eventIsLogout = (event == 'logout')

      if (eventIsLogout) {
        this.$emit('logout')
      } else {
        this.$emit('emit', event)
      }
    },
    filteredLinks: function (array, restrictToEveryRole) {
      let roles = store.state.user.roles || []
      let filteredLinksArray = []

      if (restrictToEveryRole) {
        filteredLinksArray = array.filter(function(value, index, array) {
          return !roles.every(role => array[index].disabled_to.includes(role))
        }.bind(roles))
      } else {
        filteredLinksArray = array.filter(function(value, index, array) {
          return !roles.some(role => array[index].disabled_to.includes(role))
        }.bind(roles))
      }

      return filteredLinksArray
    }
  },
  computed: {
    console: () => console,
    userRoles: () => store.state.user.roles || [],
  }
}
</script>

<style scoped>
  .user-menu-container {
    display: flex;
    height: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    opacity: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-in-out;
  }

  .user-menu-container.show {
    height: calc(100vh - 4rem);
    opacity: 1;
    transition: all 0.4s ease-in-out;
  }

  .user-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    list-style: none;
    overflow-y: auto;
  }

  .user-menu:before,
  .user-menu:after {
    display: flex;
    width: 100%;
    padding: .25rem 0;
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s ease-in-out;
  }

  .portal-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .875rem 1rem;
    text-decoration: none;
  }

  .portal-link:hover {
    background-color: rgba(0, 127, 170, .04);
  }

  .portal-link-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
  }

  .portal-link-img {
    height: auto;
    width: 75%;
  }

  .portal-link-description {
    display: flex;
    flex-direction: column;
    margin-left: 1.5rem;
  }

  .portal-link-description-title {
    color: rgba(0, 0, 0, .87);
    font-family: 'Asap Medium';
  }

  .portal-link-description-subtitle {
    color: rgba(0, 0, 0, .60);
    font-size: .75rem;
  }

  .user-menu-divider {
    margin: .5rem 1rem;
    border-bottom: 0;
  }

  @media only screen and (min-width: 48rem) {
    .user-menu-container {
      position: absolute;
      top: 3.5rem;
      right: 1rem;
      z-index: 4;
      max-width: 17.5rem;
      border: .0625rem solid rgba(0, 0, 0, .38);
      border-radius: .5rem;
      transition: all 0s ease-in-out;
    }

    .user-menu-container.show {
      height: auto;
      max-height: calc(100vh - 7rem);
    }

    .user-menu {
      transition: all 0s ease-in-out;
      height: unset;
      width: 100%;
    }
  }
</style>
